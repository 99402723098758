import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import { ErrorMessage, Params } from "../../../types/redux";

// count notification
export const countNotificationAsync = createAsyncThunk<number, Params<any>>(
  "notification/countNotification",
  async ({ params }, { rejectWithValue }) => {
    // console.log("params", params);
    try {
      const getData = (await AXIOS.get(
        `${BASE_API.countNotification}/${params?.status}`
      )) as any;

      return getData.data?.getData as number;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get count notification fail");
      return rejectWithValue(error);
    }
  }
);

// get notification
export const getNotificationAsync = createAsyncThunk<number, Params<any>>(
  "notification/getNotification",
  async ({ params }, { rejectWithValue }) => {
    try {
      const getData = (await AXIOS.get(
        `${BASE_API.getNotification}/${params?.status}`
      )) as any;

      return getData.data?.getData as number;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get get notifications fail");
      return rejectWithValue(error);
    }
  }
);

export const checkNotificationAsync = createAsyncThunk<null, Params<any>>(
  "notification/checkNotification",
  async ({ params }, { rejectWithValue }) => {
    try {
      (await AXIOS.post(
        `${BASE_API.checkNotification}/${params?.status}`
      )) as any;

      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Notification check fail");
      return rejectWithValue(error);
    }
  }
);
