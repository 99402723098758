export default function BottomDropdownIcon() {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-1"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.40922 8.0376L11.9992 12.0156L16.5892 8.0376L17.9992 9.26826L11.9992 14.4683L5.99922 9.26826L7.40922 8.0376Z"
        fill="#555B6D"
      />
    </svg>
  );
}
