export default function BillGenerateIcon() {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.125 9.75H14.875M5.125 6.75H14.875M1 16.5V2.25C1 2.05109 1.07902 1.86032 1.21967 1.71967C1.36032 1.57902 1.55109 1.5 1.75 1.5H18.25C18.4489 1.5 18.6397 1.57902 18.7803 1.71967C18.921 1.86032 19 2.05109 19 2.25V16.5L16 15L13 16.5L10 15L7 16.5L4 15L1 16.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
