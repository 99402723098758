export default function PasswordLock() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99951 12.5V14.375M7.99951 12.5C8.86246 12.5 9.56201 11.8004 9.56201 10.9375C9.56201 10.0746 8.86246 9.375 7.99951 9.375C7.13657 9.375 6.43701 10.0746 6.43701 10.9375C6.43701 11.8004 7.13657 12.5 7.99951 12.5ZM5.18701 6.875V4.0625C5.18701 3.31658 5.48333 2.60121 6.01077 2.07376C6.53822 1.54632 7.25359 1.25 7.99951 1.25C8.74543 1.25 9.4608 1.54632 9.98825 2.07376C10.5157 2.60121 10.812 3.31658 10.812 4.0625V6.875M1.74951 6.875H14.2495C14.5947 6.875 14.8745 7.15482 14.8745 7.5V16.25C14.8745 16.5952 14.5947 16.875 14.2495 16.875H1.74951C1.40433 16.875 1.12451 16.5952 1.12451 16.25V7.5C1.12451 7.15482 1.40433 6.875 1.74951 6.875Z"
        stroke="#5E718D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
