import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  // CommonOthers,
  ErrorMessage,
  Params,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";
// import {
//   GetMiterData,
//   MiterData,
// } from "../../../../types/redux/admin/miter";

// create a miter
export const createMiterAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>
>(
  "admin-miter/createMiter",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createMiter, data);
      reRender(true);
      toast("success", "Miter create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Miter create fail");
      return rejectWithValue(error);
    }
  }
);

// get all miter
export const getAllMiterAsync = createAsyncThunk<
  any[],
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>("admin-miter/getAllMiter", async ({ params }, { rejectWithValue }) => {
  try {
    const getData = await AXIOS.get(BASE_API.getAllMiter, {
      params,
    });

    return getData.data?.getData as any[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all miter data fail");
    return rejectWithValue(error);
  }
});

// // get single Miter
// export const getSingleMiterAsync = createAsyncThunk<
//   GetMiterData,
//   Params<GetSingleDataParams>,
//   {
//     rejectValue: ErrorMessage;
//   }
// >(
//   "admin-miter/getSingleMiter",
//   async ({ params }, { rejectWithValue }) => {
//     try {
//       const data = await AXIOS.get(
//         `${BASE_API.getSingleMiter}/${params?.id}`
//       );

//       return data.data?.getData as GetMiterData;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Get electricity supplier fail");
//       return rejectWithValue(error);
//     }
//   }
// );
