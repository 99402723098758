import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllRegionAsync,
  getCountRegionAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CommonParams, GetRegionData, TableData } from "../../../types/redux";
import { countRow } from "../../../utils/number";

export default function Region() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { regions, regionCount, loading } = useAppSelector(
    (state) => state.npGeo
  );

  const columns: ColumnsType<TableData<GetRegionData>> = [
    {
      title: "No.",
      align: "center",
      width: 130,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Region Name",
      dataIndex: "regionName",
    },
    {
      title: "Action",
      align: "center",
      width: 150,
      render: (val: GetRegionData) => (
        <Space size="small">
          <Link to={`/admin/geo-info/region/view-region/${val?._id}`}>
            <Button icon={<ViewIcon />} />
          </Link>
          <Link to={`/admin/geo-info/region/update-region/${val?._id}`}>
            <Button icon={<EditIcon />} />
          </Link>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllRegionAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(getCountRegionAsync());
  }, [dispatch]);

  return (
    <AdminLayout title="Region List">
      <Subheader title="Region List">
        <Space size="middle">
          <Link to="/admin/geo-info/region/add-region">
            <IconButton
              type="text"
              className="secondary-btn"
              icon={<AddIcon />}
            >
              Add Region
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<GetRegionData>>
        loading={loading}
        total={regionCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(regions)}
        // bordered={false}
      />
    </AdminLayout>
  );
}
