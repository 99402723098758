import { createSlice } from "@reduxjs/toolkit";

import { ElectricitySupplierInitialStatus } from "../../../../types/redux";
import {
  createElectricitySupplierAsync,
  getAllElectricitySupplierAsync,
} from "./electricitySupplierAPI";

const initialState: ElectricitySupplierInitialStatus<any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allElectricitySupplier: [],
  // singleElectricitySupplier: null,
  electricitySupplierCount: 0,
};

const electricitySupplierSlice = createSlice({
  name: "admin-electricitySupplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create electricitySupplier
    builder
      .addCase(createElectricitySupplierAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createElectricitySupplierAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createElectricitySupplierAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all electricitySupplier
    builder
      .addCase(getAllElectricitySupplierAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllElectricitySupplierAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allElectricitySupplier = action.payload;
      })
      .addCase(getAllElectricitySupplierAsync.rejected, (state) => {
        state.loading = false;
        state.allElectricitySupplier = [];
      });
    // get single electricitySupplier
    // builder
    //   .addCase(getSingleElectricitySupplierAsync.pending, (state) => {
    //     state.viewLoading = true;
    //   })
    //   .addCase(getSingleElectricitySupplierAsync.fulfilled, (state, action) => {
    //     state.viewLoading = false;
    //     state.singleElectricitySupplier = action.payload;
    //   })
    //   .addCase(getSingleElectricitySupplierAsync.rejected, (state) => {
    //     state.viewLoading = false;
    //     state.singleElectricitySupplier = null;
    //   });
  },
});

export default electricitySupplierSlice;
