import { Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../store/store";
import { Role } from "../types";
import { stringToArray } from "../utils/array";

type Props = {
  children: React.ReactNode;
  roles: Role[];
};

export default function AuthMiddleware({ children, roles }: Props) {
  const location = useLocation();
  const { token, siteRole, login } = useAppSelector((state) => state.login);
  const isFins = stringToArray(location.pathname, "/")?.[0] === "finance";
  const isRegional = stringToArray(location.pathname, "/")?.[0] === "regional";

  const accessRole = siteRole && roles?.length && roles?.includes(siteRole);

  return token && login?.email ? (
    accessRole ? (
      <Fragment>{children}</Fragment>
    ) : (
      <Navigate to="/404" replace />
    )
  ) : (
    <Navigate
      to={
        isFins
          ? "/finance/login"
          : isRegional
          ? "/regional/login"
          : "/admin/login"
      }
      replace
    />
  );
}
