import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { IMAGE_URL } from "../../../helpers/config";
import { toast } from "../../../helpers/toast";
import { stringToArray } from "../../../utils/array";
import NormalFileUpload from "../../common/NormalFileUpload";

type Props = {
  id: string;
  // showInput: boolean;
  onChange: (url: string | null) => void;
};

export default function BillImageUpload({ id, onChange }: Props) {
  const location = useLocation();
  const path = stringToArray(location.pathname, "/")?.[0];
  const [isImage, setIsImage] = useState(false);
  const [img, setImg] = useState<string | null>("");

  useEffect(() => {
    if (img) {
      try {
        if (path === "finance") {
          AXIOS.post(`${BASE_API.uploadFinanceBillImage}/${id}`, {
            photo: img,
          });
        } else {
          AXIOS.post(`${BASE_API.uploadBillImage}/${id}`, {
            photo: img,
          });
        }

        onChange(img);
        setIsImage(true);
      } catch (err) {
        toast("error", "Image upload fail");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, img]);

  return (
    <div className="flex items-center gap-2">
      {/* {showInput ? ( */}
      <NormalFileUpload
        name="image"
        fileNameSize={8}
        accept=".png, .jpeg, .jpg"
        onChangeImage={setImg}
        showImageName={isImage}
      />
      {/* ) : null} */}

      {isImage && IMAGE_URL && img ? (
        <div
          className={`w-[76px] h-[76px] bg-status-waiting-bg rounded-sm border border-others-secondary-border relative `}
        >
          <img
            className="w-full h-full object-cover rounded-sm"
            src={IMAGE_URL + img}
            alt=""
          />
        </div>
      ) : null}
    </div>
  );
}
