import { Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import Loader from "../../../components/common/Loader";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import { createElectricitySupplierAsync } from "../../../store/features/admin/electricitySupplier/electricitySupplierAPI";
import {
  getAllAreaAsync,
  getAllRegionAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";

const navLink = "/admin/electricity-supplier";

export default function ManageElectricitySupplier() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectRegion, setSelectRegion] = useState("");
  const { regions, areas } = useAppSelector((state) => state.npGeo);
  const { postLoading, updateLoading, viewLoading } = useAppSelector(
    (state) => state.electricitySupplier
  );

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { region, area, dob, ...rest } = values;

    const newValue = {
      ...rest,
      region,
      area,
      regionName: regions?.find((el) => el?._id === region)?.regionName || "",
      areaName: areas?.find((el) => el?._id === area)?.areaName || "",
      // dob: formatDate(dob?.format(), "YYYY-MM-DD"),
    };

    if (id) {
      // dispatch(
      //   updateTerritoryAsync({
      //     data: values,
      //     others: {
      //       reRender,
      //       id,
      //     },
      //   })
      // );
    } else {
      dispatch(
        createElectricitySupplierAsync({
          data: newValue,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getAllAreaAsync({
        params: {
          region: selectRegion,
        },
      })
    );
  }, [dispatch, selectRegion]);

  // useEffect(() => {
  //   if ((id || viewId) && singleTerritory && updateLoading === false) {
  //     setSelectRegion(singleTerritory?.region?._id);

  //     form.setFieldsValue({
  //       region: singleTerritory?.region?._id,
  //       area: singleTerritory?.area?._id,
  //       teritoryName: singleTerritory?.teritoryName,
  //     });
  //   }
  // }, [form, id, singleTerritory, updateLoading, viewId]);

  // useEffect(() => {
  //   if (id || viewId) {
  //     dispatch(getSingleTerritoryAsync({ params: { id: id || viewId || "" } }));
  //   }
  // }, [dispatch, id, viewId]);

  const title = id
    ? "Update Electricity Supplier Company"
    : viewId
    ? "View Electricity Supplier Company"
    : "Add New Electricity Supplier Company";

  return (
    <AdminLayout title={title}>
      {viewLoading ? <Loader /> : null}
      <CustomForm
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        disabledSubmit={viewLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="region"
          label="Select Region"
          rules={[
            {
              required: true,
              message: "Please select region",
            },
          ]}
        >
          <Select
            placeholder="Select region"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(regions, "regionName", "_id")}
            onChange={(val) => {
              setSelectRegion(val);
              form.setFieldValue("area", undefined);
              form.setFieldValue("teritoryName", undefined);
            }}
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="area"
          label="Select Area"
          rules={[
            {
              required: true,
              message: "Please select area",
            },
          ]}
        >
          <Select
            placeholder="Select area"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(areas, "areaName", "_id")}
            disabled={!selectRegion || viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="companyName"
          label="Electricity Supplier Company Name"
          rules={[
            {
              required: true,
              message: "Please enter supplier company name",
            },
          ]}
        >
          <Input
            size="large"
            disabled={viewId ? true : false}
            placeholder="Enter EPSC name"
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="Address"
          rules={[
            {
              required: true,
              message: "Please enter address",
            },
          ]}
        >
          <Input
            size="large"
            disabled={viewId ? true : false}
            placeholder="Enter address"
          />
        </Form.Item>
        {/* <Form.Item
          name="dob"
          label="Date"
          rules={[
            {
              required: true,
              message: "Please enter date",
            },
          ]}
        >
          <DatePicker
            format="DD/MM/YY"
            style={{ width: "100%" }}
            placeholder="dd/mm/yy"
            suffixIcon={<DateIcon />}
          />
        </Form.Item> */}
      </CustomForm>
    </AdminLayout>
  );
}
