export const currency = "AED";

export const formatPrice = (
  price: string | number = 0,
  locale = "en-US",
  currencyType = currency
) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyType,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formatter.format(Number(price || 0));
};

export const AddZero = (num: number) => {
  if (num < 10 && num > -10) {
    return num < 10 && num >= 0 ? `0${num}` : `-0${Math.abs(num)}`;
  }

  return num;
};

export const countRow = (page: number, limit: number, index: number) => {
  return index + 1 + (page - 1) * limit;
};

// sum all data
export const sumOfArray = (arr: any[], field: string) => {
  return arr?.reduce((acc, curr) => acc + curr?.[field] || 0, 0);
};

export const percentage = (value: number, total: number) => {
  return (value / total) * 100;
};

export function numberShort(number: number) {
  if (number >= 1000 && number < 1000000) {
    return (number / 1000).toFixed(2).replace(/\.00$/, "") + "K";
  } else if (number >= 1000000 && number < 1000000000) {
    return (number / 1000000).toFixed(2).replace(/\.00$/, "") + "M";
  } else if (number >= 1000000000) {
    return (number / 1000000000).toFixed(2).replace(/\.00$/, "") + "B";
  } else {
    return number.toString();
  }
}
