import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  // CommonOthers,
  ErrorMessage,
  Params,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";
// import {
//   GetBillGenerateData,
//   BillGenerateData,
// } from "../../../../types/redux/admin/billGenerate";

// get all billGenerate
export const getAllBillGenerateAsync = createAsyncThunk<
  any[],
  PostRequest<any, any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-billGenerate/getAllBillGenerate",
  async ({ data }, { rejectWithValue }) => {
    try {
      const getData = await AXIOS.post(BASE_API.getAllBillGenerate, data);

      return getData.data?.getData as any[];
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get all billGenerate data fail");
      return rejectWithValue(error);
    }
  }
);

// // get single BillGenerate
// export const getSingleBillGenerateAsync = createAsyncThunk<
//   GetBillGenerateData,
//   Params<GetSingleDataParams>,
//   {
//     rejectValue: ErrorMessage;
//   }
// >(
//   "admin-billGenerate/getSingleBillGenerate",
//   async ({ params }, { rejectWithValue }) => {
//     try {
//       const data = await AXIOS.get(
//         `${BASE_API.getSingleBillGenerate}/${params?.id}`
//       );

//       return data.data?.getData as GetBillGenerateData;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Get electricity supplier fail");
//       return rejectWithValue(error);
//     }
//   }
// );

export const uploadBillAsync = createAsyncThunk<
  null,
  PostRequest<any[], any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-billGenerate/uploadBillGenerate",
  async (
    { data, others: { reRender, uploadedCount } },
    { rejectWithValue }
  ) => {
    const totalItems = data.length;
    let completedItems = 0;
    // let hasError = false;
    let isCreate = false;

    try {
      const uploadPromises = data.map(async (el: any) => {
        try {
          // First API call to create the payment
          const paymentResponse = await AXIOS.post(
            BASE_API.createBillGenerate,
            el,
            {
              onUploadProgress: () => {
                completedItems++;
                const progressPercentage = Math.round(
                  (completedItems / totalItems) * 100
                );
                uploadedCount(progressPercentage, 100);
              },
            }
          );

          const ticketId = paymentResponse?.data;
          isCreate = true;

          if (!ticketId) {
            console.error("Bill ID not found in response:", paymentResponse);
            throw new Error("Bill ID not found in response");
          }

          // Second API call to send the notification
          // await AXIOS.post(BASE_API.towerTicketNotification, {
          //   towerTicketId: ticketId,
          // });
        } catch (uploadError) {
          // hasError = true;
          // console.error("Error during upload process:", uploadError);
          const errors = uploadError as AxiosError;
          const error = errors.response?.data as ErrorMessage;
          toast("error", error?.message || "Bill upload failed");
        } finally {
          // Always increment completedItems and update progress bar
          completedItems++;
          const progressPercentage = Math.round(
            (completedItems / totalItems) * 100
          );
          uploadedCount(progressPercentage, 100);
        }
      });

      // Wait for all promises to settle (either resolved or rejected)
      await Promise.allSettled(uploadPromises);

      // Show error toast if any upload failed
      // if (hasError) {
      //   toast(
      //     "error",
      //     "Some tickets failed to create. Please check the log for more details."
      //   );
      // } else {
      //   toast("success", "All tickets created successfully");
      // }

      if (isCreate) {
        toast("success", "Bill data upload successfully");
      }

      // Call reRender function after all uploads are complete
      reRender(true);

      return null;
    } catch (err) {
      // console.error("Final Error:", err);
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bill upload failed");
      return rejectWithValue(error);
    }
  }
);

// request to bill
export const requestToBillAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>
>(
  "admin-billGenerate/requestToBill",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.requestToBill, data);
      reRender(true);
      toast(
        "success",
        data?.status === 2
          ? "Send bill to admin"
          : "Request to pay bill successfully"
      );
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bill pay fail");
      return rejectWithValue(error);
    }
  }
);

// bill send to admin
export const billSendToAdminAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>
>(
  "admin-billGenerate/requestToBill",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      (await AXIOS.post(BASE_API.billSendToAdmin, data)) as any;
      reRender(true);
      // postData?.message ||
      toast("success", "Bill approved successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Bill pay fail");
      return rejectWithValue(error);
    }
  }
);

// get last five day remaining
export const getLastFiveDayRemainingAsync = createAsyncThunk<
  number,
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-billGenerate/getLastFiveDayRemaining",
  async ({ params }, { rejectWithValue }) => {
    try {
      const getData = await AXIOS.get(BASE_API.getLastFiveDayRemaining, {
        params,
      });

      return getData.data?.getData as number;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get remaining data fail");
      return rejectWithValue(error);
    }
  }
);

// get last five day remaining data
export const getLastFiveDayRemainingDataAsync = createAsyncThunk<
  any[],
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-billGenerate/getLastFiveDayRemainingData",
  async ({ params }, { rejectWithValue }) => {
    try {
      const getData = await AXIOS.get(BASE_API.getLastFiveDayRemainingData, {
        params,
      });

      return getData.data?.getData as any[];
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get remaining data fail");
      return rejectWithValue(error);
    }
  }
);
