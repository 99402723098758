import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import { ErrorMessage } from "../../../../types/redux";

// get all Region
export const getMonthYearBillAsync = createAsyncThunk<
  any[],
  null,
  {
    rejectValue: ErrorMessage;
  }
>("admin-dashboard/getMonthYearBill", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getMonthYearBill);

    return data.data?.getData as any[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get month & year bill fail");
    return rejectWithValue(error);
  }
});

export const getSiteBillAsync = createAsyncThunk<
  any[],
  null,
  {
    rejectValue: ErrorMessage;
  }
>("admin-dashboard/getSiteBill", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getSiteBill);

    return data.data?.getData as any[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get site bill fail");
    return rejectWithValue(error);
  }
});
