import { createSlice } from "@reduxjs/toolkit";
import { DashboardInitialStatus } from "../../../../types/redux/admin/dashbaord";
import { getMonthYearBillAsync, getSiteBillAsync } from "./dashboardAPI";

const initialState: DashboardInitialStatus<any, any> = {
  loading: false,
  monthYearBill: [],
  getSiteBill: [],
};

const adminDashboardSlice = createSlice({
  name: "admin-dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMonthYearBillAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMonthYearBillAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.monthYearBill = action.payload;
      })
      .addCase(getMonthYearBillAsync.rejected, (state) => {
        state.loading = false;
        state.monthYearBill = [];
      });
    builder
      .addCase(getSiteBillAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSiteBillAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.getSiteBill = action.payload;
      })
      .addCase(getSiteBillAsync.rejected, (state) => {
        state.loading = false;
        state.getSiteBill = [];
      });
  },
});

export default adminDashboardSlice;
