export default function DownloadIcon() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="-mb-[1px]"
    >
      <path
        d="M8.30119 10.2385L10.6877 12.6249M10.6877 12.6249L13.0741 10.2385M10.6877 12.6249V6.99994M6.75017 12.6249H5.06267C4.50427 12.6244 3.95238 12.5051 3.44362 12.2749C2.93486 12.0448 2.48087 11.7091 2.11178 11.29C1.74269 10.871 1.46695 10.3783 1.30285 9.84455C1.13876 9.31081 1.09007 8.74827 1.16001 8.19427C1.22994 7.64027 1.41692 7.10748 1.70851 6.63127C2.00011 6.15506 2.38966 5.74632 2.8513 5.43217C3.31295 5.11802 3.83613 4.90565 4.38613 4.80917C4.93612 4.71268 5.50035 4.73428 6.04136 4.87253M5.62517 6.99994C5.62525 6.10868 5.83712 5.2302 6.24331 4.43689C6.64951 3.64358 7.23839 2.95814 7.96145 2.43705C8.6845 1.91597 9.52103 1.57416 10.4021 1.43979C11.2832 1.30541 12.1836 1.38233 13.0291 1.66419C13.8746 1.94605 14.641 2.42479 15.2652 3.06096C15.8894 3.69712 16.3535 4.47251 16.6193 5.32322C16.885 6.17394 16.9448 7.07562 16.7938 7.95398C16.6427 8.83234 16.2851 9.66223 15.7503 10.3753"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
