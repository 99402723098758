import { Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import Loader from "../../../components/common/Loader";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  createAreaAsync,
  getAllRegionAsync,
  getSingleAreaAsync,
  getSingleRegionAsync,
  updateAreaAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { AreaData } from "../../../types/redux";

const navLink = "/admin/geo-info/area";

export default function ManageArea() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectRegion, setSelectRegion] = useState("");
  const {
    postLoading,
    regions,
    loading,
    singleArea,
    updateLoading,
    viewLoading,
  } = useAppSelector((state) => state.npGeo);

  const onFinish: FormProps<AreaData>["onFinish"] = (values) => {
    if (id) {
      dispatch(
        updateAreaAsync({
          data: values,
          others: {
            reRender,
            id,
          },
        })
      );
    } else {
      dispatch(
        createAreaAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);

  useEffect(() => {
    if ((id || viewId) && singleArea && updateLoading === false) {
      setSelectRegion(singleArea?.region?._id);
      form.setFieldsValue({
        region: singleArea?.region?._id,
        areaName: singleArea?.areaName,
      });
    }
  }, [form, id, singleArea, updateLoading, viewId]);

  useEffect(() => {
    if (id || viewId) {
      dispatch(getSingleRegionAsync({ params: { id: id || viewId || "" } }));
    }
  }, [dispatch, id, viewId]);

  useEffect(() => {
    if (id || viewId) {
      dispatch(getSingleAreaAsync({ params: { id: id || viewId || "" } }));
    }
  }, [dispatch, id, viewId]);

  const title = id ? "Update Area" : viewId ? "View Area" : "Add New Area";

  return (
    <AdminLayout title={title}>
      {viewLoading ? <Loader /> : null}
      <CustomForm<AreaData>
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        disabledSubmit={viewLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="region"
          label="Select Region"
          rules={[
            {
              required: true,
              message: "Please select region",
            },
          ]}
        >
          <Select
            placeholder="Select region"
            size="large"
            allowClear
            showSearch
            loading={loading}
            filterOption={selectSearchOption}
            options={ArrayOption(regions, "regionName", "_id")}
            onChange={(val) => {
              setSelectRegion(val);
              form.setFieldValue("areaName", undefined);
            }}
            disabled={id || viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="areaName"
          label="Area Name"
          rules={[
            {
              required: true,
              message: "Please enter area name",
            },
          ]}
        >
          <Input
            size="large"
            disabled={!selectRegion || viewId ? true : false}
            placeholder="Enter area name"
          />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
