export default function ThreeDot() {
  return (
    <svg
      width="6"
      height="18"
      viewBox="0 0 6 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-0.5"
    >
      <path
        d="M3 17.125C4.03553 17.125 4.875 16.2855 4.875 15.25C4.875 14.2145 4.03553 13.375 3 13.375C1.96447 13.375 1.125 14.2145 1.125 15.25C1.125 16.2855 1.96447 17.125 3 17.125Z"
        stroke="#5E718D"
        strokeMiterlimit="10"
      />
      <path
        d="M3 10.875C4.03553 10.875 4.875 10.0355 4.875 9C4.875 7.96447 4.03553 7.125 3 7.125C1.96447 7.125 1.125 7.96447 1.125 9C1.125 10.0355 1.96447 10.875 3 10.875Z"
        stroke="#5E718D"
        strokeMiterlimit="10"
      />
      <path
        d="M3 4.625C4.03553 4.625 4.875 3.78553 4.875 2.75C4.875 1.71447 4.03553 0.875 3 0.875C1.96447 0.875 1.125 1.71447 1.125 2.75C1.125 3.78553 1.96447 4.625 3 4.625Z"
        stroke="#5E718D"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
