export default function MeterInfoIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.81829 15.3035C5.75763 18.4855 1.51499 18.4855 1.51499 18.4855C1.51499 18.4855 1.51499 14.2428 4.69697 13.1822M10.0003 14.2428L16.3642 7.87888C18.774 5.46912 18.7505 3.05935 18.5972 2.03165C18.5736 1.87342 18.4998 1.72695 18.3866 1.61382C18.2735 1.5007 18.127 1.4269 17.9688 1.40332C16.9411 1.24998 14.5314 1.22648 12.1216 3.63624L5.75763 10.0002M10.0003 14.2428L5.75763 10.0002M10.0003 14.2428L10.7861 18.172C10.8127 18.3051 10.875 18.4285 10.9662 18.529C11.0575 18.6295 11.1743 18.7034 11.3042 18.7427C11.4341 18.782 11.5723 18.7854 11.704 18.7524C11.8356 18.7193 11.9559 18.6512 12.0519 18.5552L15.0839 15.5232C15.1535 15.4535 15.2088 15.3709 15.2465 15.2799C15.2842 15.1889 15.3036 15.0913 15.3036 14.9928V8.93954M5.75763 10.0002L1.8285 9.21438C1.69539 9.18775 1.57199 9.1255 1.47148 9.03426C1.37097 8.94302 1.29711 8.8262 1.25777 8.69628C1.21843 8.56635 1.21509 8.42819 1.24811 8.29652C1.28113 8.16484 1.34927 8.0446 1.44526 7.94861L4.4773 4.91657C4.54694 4.84693 4.62962 4.79168 4.72061 4.75399C4.81161 4.7163 4.90914 4.6969 5.00763 4.6969H11.0609"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
