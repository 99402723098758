export default function ReloadIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.61328 8.98872H1.23828M1.23828 8.98872V12.3637M1.23828 8.98872L3.6248 11.3752C4.19936 11.9498 4.88147 12.4056 5.63217 12.7165C6.38287 13.0275 7.18747 13.1875 8.00002 13.1875C8.81258 13.1875 9.61718 13.0275 10.3679 12.7165C11.1186 12.4056 11.8007 11.9498 12.3752 11.3752M11.3868 5.01128H14.7618M14.7618 5.01128V1.63628M14.7618 5.01128L12.3752 2.62478C11.8007 2.05021 11.1186 1.59445 10.3679 1.2835C9.61718 0.972545 8.81258 0.8125 8.00002 0.8125C7.18747 0.8125 6.38287 0.972545 5.63217 1.2835C4.88147 1.59445 4.19936 2.05021 3.6248 2.62478"
        stroke="#455468"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
