import { Form, FormProps, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import Loader from "../../../components/common/Loader";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllElectricitySupplierAsync } from "../../../store/features/admin/electricitySupplier/electricitySupplierAPI";
import {
  getAllAreaAsync,
  getAllRegionAsync,
  getAllTerritoryAsync,
  getSingleTerritoryAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { createMiterAsync } from "../../../store/features/admin/miter/miterAPI";
import { getAllTowerAsync } from "../../../store/features/admin/network/networkAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TerritoryData } from "../../../types/redux";
import { stringToArray } from "../../../utils/array";

export default function ManageMeterInfo() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [selectRegion, setSelectRegion] = useState("");
  const [selectArea, setSelectArea] = useState("");
  const [selectTerritory, setSelectTerritory] = useState("");
  const { allTowers } = useAppSelector((state) => state.network);
  const { postLoading, updateLoading, viewLoading } = useAppSelector(
    (state) => state.miter
  );
  const { allElectricitySupplier } = useAppSelector(
    (state) => state.electricitySupplier
  );
  const { regions, areas, singleTerritory, territorys } = useAppSelector(
    (state) => state.npGeo
  );
  const path = stringToArray(location.pathname, "/")?.[0];
  const navLink = `/${path}/meter-info`;

  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const { region, area, teritory, company, ...rest } = values;
    const newValue = {
      ...rest,
      region,
      area,
      teritory,
      company,
      regionName: regions?.find((el) => el?._id === region)?.regionName || "",
      areaName: areas?.find((el) => el?._id === area)?.areaName || "",
      teritoryName:
        territorys?.find((el) => el?._id === teritory)?.teritoryName || "",
      companyName:
        allElectricitySupplier?.find((el) => el?._id === company)
          ?.companyName || "",
    };

    if (id) {
      // dispatch(
      //   updateTerritoryAsync({
      //     data: values,
      //     others: {
      //       reRender,
      //       id,
      //     },
      //   })
      // );
    } else {
      dispatch(
        createMiterAsync({
          data: newValue,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getAllAreaAsync({
        params: {
          region: selectRegion,
        },
      })
    );
  }, [dispatch, selectRegion]);
  useEffect(() => {
    dispatch(
      getAllTerritoryAsync({
        params: {
          area: selectArea,
        },
      })
    );
  }, [dispatch, selectArea]);

  useEffect(() => {
    if ((id || viewId) && singleTerritory && updateLoading === false) {
      setSelectRegion(singleTerritory?.region?._id);

      form.setFieldsValue({
        region: singleTerritory?.region?._id,
        area: singleTerritory?.area?._id,
        teritoryName: singleTerritory?.teritoryName,
      });
    }
  }, [form, id, singleTerritory, updateLoading, viewId]);

  useEffect(() => {
    if (id || viewId) {
      dispatch(getSingleTerritoryAsync({ params: { id: id || viewId || "" } }));
    }
  }, [dispatch, id, viewId]);

  useEffect(() => {
    dispatch(
      getAllTowerAsync({
        data: {
          filter: [],
          towerName: "",
          teritory: selectTerritory,
        },
        others: { limit: 10000, skip: 0 },
      })
    );
  }, [dispatch, selectTerritory]);

  useEffect(() => {
    // const params: CommonParams = {
    //   limit: limit,
    //   skip: limit * (page - 1),
    // };

    dispatch(getAllElectricitySupplierAsync({}));
  }, [dispatch]);

  const title = id ? "Update Meter" : viewId ? "View Meter" : "Add New Meter";

  return (
    <AdminLayout title={title}>
      {viewLoading ? <Loader /> : null}
      <CustomForm<TerritoryData>
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        disabledSubmit={viewLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="miterName"
          label="Meter Name"
          rules={[
            {
              required: true,
              message: "Please enter meter name",
            },
          ]}
        >
          <Input
            size="large"
            disabled={viewId ? true : false}
            placeholder="Name"
          />
        </Form.Item>
        <Form.Item
          name="miterType"
          label="Meter Type"
          rules={[
            {
              required: true,
              message: "Please select meter type",
            },
          ]}
        >
          <Select
            placeholder="Select"
            size="large"
            allowClear
            showSearch
            // loading={loading}
            filterOption={selectSearchOption}
            options={[
              {
                label: "Prepaid",
                value: "pre",
              },
              {
                label: "Postpaid",
                value: "post",
              },
            ]}
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="miterNumber"
          label="Meter number"
          rules={[
            {
              required: true,
              message: "Please enter meter number",
            },
            {
              validator: async (_: any, value: number) => {
                if (!value) {
                  return Promise.resolve();
                }

                if (value < 0) {
                  return Promise.reject(
                    new Error("Meter number can't be negative")
                  );
                }

                if (!Number.isInteger(value)) {
                  return Promise.reject(
                    new Error("Meter number must be an integer")
                  );
                }
              },
            },
          ]}
        >
          <InputNumber
            className="w-full"
            size="large"
            placeholder="Meter number"
          />
        </Form.Item>

        <Form.Item
          name="region"
          label="Region"
          rules={[
            {
              required: true,
              message: "Please select region",
            },
          ]}
        >
          <Select
            placeholder="Select region"
            size="large"
            allowClear
            showSearch
            // loading={loading}
            filterOption={selectSearchOption}
            options={ArrayOption(regions, "regionName", "_id")}
            onChange={(val) => {
              setSelectRegion(val);
              setSelectArea("");
              setSelectTerritory("");
              form.setFieldValue("area", undefined);
              form.setFieldValue("teritoryName", undefined);
              form.setFieldValue("tower", undefined);
            }}
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            name="area"
            label="Area"
            rules={[
              {
                required: true,
                message: "Please select area",
              },
            ]}
          >
            <Select
              placeholder="Select area"
              size="large"
              allowClear
              showSearch
              // loading={loading}
              filterOption={selectSearchOption}
              options={ArrayOption(areas, "areaName", "_id")}
              onChange={(val) => {
                setSelectArea(val);
                setSelectTerritory("");
                form.setFieldValue("teritory", undefined);
                form.setFieldValue("tower", undefined);
              }}
              disabled={!selectRegion || viewId ? true : false}
            />
          </Form.Item>

          <Form.Item
            name="teritory"
            label="Territory"
            rules={[
              {
                required: true,
                message: "Please select territory",
              },
            ]}
          >
            <Select
              placeholder="Select territory"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(territorys, "teritoryName", "_id")}
              disabled={!selectArea}
              onChange={(val) => {
                setSelectTerritory(val);
                form.setFieldValue("tower", undefined);
              }}
            />
          </Form.Item>

          {/* <Form.Item
            name="geo"
            label="GEO"
            rules={[
              {
                required: true,
                message: "Please select territory",
              },
            ]}
          >
            <Select
              placeholder="Select GEO"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(territorys, "teritoryName", "_id")}
              disabled={!selectArea}
            />
          </Form.Item> */}
        </div>
        <Form.Item
          name="tower"
          label="Tower/Site"
          rules={[
            {
              required: true,
              message: "Please enter tower/site",
            },
          ]}
        >
          <Select
            placeholder="Select tower/site"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(allTowers, "towerName", "_id", true)}
            disabled={viewId || !selectTerritory ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="company"
          label="Electricity Power Supplier"
          rules={[
            {
              required: true,
              message: "Please enter power supplier",
            },
          ]}
        >
          <Select
            placeholder="Select"
            size="large"
            allowClear
            showSearch
            filterOption={selectSearchOption}
            options={ArrayOption(
              allElectricitySupplier,
              "companyName",
              "_id",
              "up"
            )}
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.miterType !== currentValues.miterType
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("miterType") === "pre" ? (
              <Form.Item
                name="gapDay"
                label="Next payment day"
                rules={[
                  ({ getFieldValue }) => ({
                    validator: async (_, value: number) => {
                      if (getFieldValue("miterType") === "pre" && !value) {
                        return Promise.reject(
                          new Error("Please enter next payment day")
                        );
                      }

                      if (value && value < 0) {
                        return Promise.reject(
                          new Error("Day can't be negative")
                        );
                      }

                      if (value && !Number.isInteger(value)) {
                        return Promise.reject(
                          new Error("Day must be an integer")
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  className="w-full"
                  size="large"
                  placeholder="Next payment day"
                />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
