import { Form, FormProps, Input } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import Loader from "../../../components/common/Loader";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  createRegionAsync,
  getSingleRegionAsync,
  updateRegionAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { RegionData } from "../../../types/redux";

const navLink = "/admin/geo-info/region";

export default function ManageRegion() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { postLoading, updateLoading, singleRegion, viewLoading } =
    useAppSelector((state) => state.npGeo);

  const onFinish: FormProps<RegionData>["onFinish"] = (values) => {
    if (id) {
      dispatch(
        updateRegionAsync({
          data: values,
          others: {
            reRender,
            id,
          },
        })
      );
    } else {
      dispatch(
        createRegionAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    if ((id || viewId) && singleRegion && updateLoading === false) {
      form.setFieldsValue({
        regionName: singleRegion.regionName,
      });
    }
  }, [form, id, singleRegion, updateLoading, viewId]);

  useEffect(() => {
    if (id || viewId) {
      dispatch(getSingleRegionAsync({ params: { id: id || viewId || "" } }));
    }
  }, [dispatch, id, viewId]);

  const title = id
    ? "Update Region"
    : viewId
    ? "View Region"
    : "Add New Region";

  return (
    <AdminLayout title={title}>
      {viewLoading ? <Loader /> : null}
      <CustomForm<RegionData>
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        disabledSubmit={viewLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="regionName"
          label="Region Name"
          rules={[
            {
              required: true,
              message: "Please enter region name",
            },
          ]}
        >
          <Input
            size="large"
            disabled={viewId ? true : false}
            placeholder="Enter region name"
          />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
