import { Button, Form, FormProps, Input, Select } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { ArrayOption, selectSearchOption } from "../../helpers/siteConfig";
import { billSendToAdminAsync } from "../../store/features/admin/billGenerate/billGenerateAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CustomModalProps } from "../../types";
import CustomModal from "../common/CustomModal";

type Props = {
  reRender: (val: boolean) => void;
  checkValue: "All" | "post" | "pre";
} & CustomModalProps;

export default function GenerateBill({
  handleClose,
  data,
  reRender,
  checkValue,
  ...rest
}: Props) {
  const [form] = Form.useForm();
  const { updateLoading } = useAppSelector((state) => state.billGenerate);
  const dispatch = useAppDispatch();
  const onFinish: FormProps<any>["onFinish"] = (values) => {
    const postData = data?.map((el: any) => ({
      id: el?.key,
      token: "",
      date: moment().format("MM-DD-YYYY"),
    }));

    const newData = values?.paymentId?.map((el: any) => ({
      id: el?.id,
      token: el?.token,
      date: moment().format("MM-DD-YYYY"),
    }));

    dispatch(
      billSendToAdminAsync({
        data: {
          paymentId: checkValue === "post" ? postData : newData,
          status: 4,
        },
        others: {
          reRender,
        },
      })
    );
  };

  useEffect(() => {
    if (data?.length) {
      form.setFieldsValue({
        paymentId: data?.map((el: any) => ({
          id: el?.key,
        })),
      });
    }
  }, [data, form]);

  return (
    <CustomModal
      width={checkValue === "post" ? 450 : 700}
      handleClose={handleClose}
      title="Bill Generate"
      {...rest}
    >
      <div className="p-4">
        <Form
          className="custom-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          form={form}
        >
          {checkValue !== "post" ? (
            data?.map((el: any, idx: number) => (
              <div className="grid grid-cols-2 gap-4" key={el?.key}>
                <Form.Item
                  name={["paymentId", idx, "id"]}
                  label="Meter Name"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter a token",
                  //   },
                  // ]}
                >
                  <Select
                    placeholder="Select"
                    size="large"
                    allowClear
                    showSearch
                    filterOption={selectSearchOption}
                    options={ArrayOption(data, "miterName", "_id", true)}
                    disabled
                  />
                </Form.Item>
                {el?.miterType === "pre" ? (
                  <Form.Item
                    name={["paymentId", idx, "token"]}
                    label="Meter Token"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a token",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter area a meter token"
                    />
                  </Form.Item>
                ) : (
                  <div />
                )}
              </div>
            ))
          ) : (
            <div className="text-lg text-body">
              Are you sure to generate bill?
            </div>
          )}

          <div className="w-full justify-center flex gap-4 pt-3">
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              //   block
              loading={updateLoading}
              //   {...buttonProps}
            >
              Generate
            </Button>
          </div>
        </Form>
      </div>
    </CustomModal>
  );
}
