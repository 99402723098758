import { createSlice } from "@reduxjs/toolkit";
import {
  countNotificationAsync,
  getNotificationAsync,
} from "./notificationAPI";

const initialState: any = {
  loading: false,
  countNotification: 0,
  allNotification: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(countNotificationAsync.pending, (state) => {
        state.loading = true;
        state.countNotification = 0;
      })
      .addCase(countNotificationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.countNotification = action?.payload || 0;
      })
      .addCase(countNotificationAsync.rejected, (state) => {
        state.loading = false;
        state.countNotification = 0;
      });
    builder
      .addCase(getNotificationAsync.pending, (state) => {
        state.loading = true;
        state.allNotification = [];
      })
      .addCase(getNotificationAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allNotification = action?.payload || [];
      })
      .addCase(getNotificationAsync.rejected, (state) => {
        state.loading = false;
        state.allNotification = [];
      });
  },
});

export default notificationSlice;
