import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import billGenerateSlice from "./features/admin/billGenerate/billGenerateSlice";
import adminDashboardSlice from "./features/admin/dashboard/dashboardSlice";
import electricitySupplierSlice from "./features/admin/electricitySupplier/electricitySupplierSlice";
import npGeoSlice from "./features/admin/geoInfo/geoSlice";
import miterSlice from "./features/admin/miter/miterSlice";
import networkSlice from "./features/admin/network/networkSlice";
import appSlice from "./features/appSlice";
import loginSlice from "./features/auth/loginSlice";
import notificationSlice from "./features/notifications/notificationSlice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    login: loginSlice.reducer,
    npGeo: npGeoSlice.reducer,
    network: networkSlice.reducer,
    electricitySupplier: electricitySupplierSlice.reducer,
    miter: miterSlice.reducer,
    billGenerate: billGenerateSlice.reducer,
    adminDashboard: adminDashboardSlice.reducer,
    notification: notificationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
