import {
  DatePicker,
  Progress,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useState } from "react";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import PayBillIcon from "../../../assets/icons/PayBillIcon";
import ReloadIcon from "../../../assets/icons/ReloadIcon";
import BillImageUpload from "../../../components/admin/BillGenerate/BillImageUpload";
import ImportExcel from "../../../components/admin/BillGenerate/ImportExcel";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import GenerateBill from "../../../components/finance/GenerateBill";
import RequestBillSuccessfully from "../../../components/ui/RequestBillSuccessfully";
import Subheader from "../../../components/ui/Subheader";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { IMAGE_URL } from "../../../helpers/config";
import { addKeyInArray, selectSearchOption } from "../../../helpers/siteConfig";
import useExcelDownload from "../../../hooks/useExcelDownload";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllBillGenerateAsync } from "../../../store/features/admin/billGenerate/billGenerateAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TableData } from "../../../types/redux";
import { countRow, percentage } from "../../../utils/number";
import { capitalize } from "../../../utils/string";

export default function BillGenerate() {
  const dispatch = useAppDispatch();
  const { open, toggle, handleOpen } = useModal();
  const [checkValue, setCheckValue] = useState<"All" | "post" | "pre">("All");
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const {
    limit,
    page,
    handlePagination,
    handleLimit,
    isFirstPage,
    handleSetFirstPage,
  } = useTable();
  const { postLoading, allBillGenerate, loading, updateLoading } =
    useAppSelector((state) => state.billGenerate);
  const [uploadCount, setUploadCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [month, setMonth] = useState<string | undefined>(undefined);
  const [year, setYear] = useState<Dayjs | null>(dayjs());
  // const { handleInputChange } = useDebounce(1000);
  const { excelLoading, downloadExcel, handleExcelLoading } = useExcelDownload(
    "Bill-Generated-Data"
  );
  const [uploadedFile, setUploadedFile] = useState<
    { key: string; value: string }[]
  >([]);

  const billGenerateData = allBillGenerate?.slice(
    (page - 1) * limit,
    limit * page
  );

  // useEffect(() => {
  //   if (checkValue === "All") {
  //     const allKeys = addKeyInArray(allBillGenerate)?.map(
  //       (territory: any) => territory?.key
  //     );
  //     setSelectedData(allKeys);
  //   }
  // }, [checkValue, allBillGenerate]);

  // useEffect(() => {
  //   if (allBillGenerate?.length) {
  //     if (allBillGenerate?.length === selectedData?.length) {
  //       setCheckValue("All");
  //     } else {
  //       setCheckValue("");
  //     }
  //   }
  // }, [allBillGenerate?.length, selectedData?.length]);

  const handleImgChange = (img: string, key: string) => {
    setUploadedFile((prev: any) => {
      const exists = prev?.some((el: any) => el?.key === key);

      if (exists) {
        return prev?.map((el: any) => {
          if (el?.key === key) {
            return {
              ...el,
              value: img,
            };
          }
          return el;
        });
      } else {
        return [...prev, { key, value: img }];
      }
    });
  };

  const columns: ColumnsType<TableData<any>> = [
    {
      title: "No.",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Meter name",
      dataIndex: "miterName",
      render: (val) => (
        <span className="text-header text-base">{capitalize(val)}</span>
      ),
    },
    {
      title: "Site name & Code",
      dataIndex: "tower",
      render: (val) => (
        <div>
          <span className="font-semibold">{capitalize(val?.towerName)}</span>
          <br />
          <small className="text-xs font-normal leading-tight tracking-tight text-accent">
            Site Code: {val?.siteCode}
          </small>
        </div>
      ),
    },
    {
      title: "Month",
      dataIndex: "month",
      render: (val) => capitalize(val),
    },
    {
      title: "Bill Amount",
      render: (val) => {
        const avcNumber = (val?.billAmount || 0) / (val?.lastThreeMonth || 0);
        const avc = parseInt(avcNumber * 100 + "");

        return (
          <div>
            <p className="text-others-info !text-base leading-7">
              {val?.billAmount} BDT
            </p>
            {avcNumber !== 1 ? (
              <span className="text-body !text-sm font-medium">
                {avc > 0 ? "+" : ""}
                {avc}
              </span>
            ) : null}
          </div>
        );
      },
    },
    {
      title: () => (
        <span>
          Last 3 Bill <br /> Average Amount
        </span>
      ),
      render: (val, _) => (
        <span
        // className={`${
        //   val?.status === 1
        //     ? "text-status-danger-text"
        //     : "text-status-complete-text"
        // }`}
        >
          {val?.lastThreeMonth || 0} BDT
        </span>
      ),
    },
    // {
    //   title: () => <span>Bill Average</span>,
    //   render: (val, _, idx) => (
    //     <span
    //       className={`flex items-center gap-1.5 ${
    //         idx % 2 === 0
    //           ? "text-status-danger-text"
    //           : "text-status-complete-text"
    //       }`}
    //     >
    //       {(0.75 * (idx + 0.5)).toFixed(2)}%
    //       {idx % 2 === 0 ? <UpArrowIcon /> : <DownArrowIcon />}
    //     </span>
    //   ),
    // },

    {
      title: "EPSC",
      dataIndex: "companyName",
      render: (val) => val?.toUpperCase(),
    },
    {
      title: "Meter type",
      dataIndex: "miterType",
      render: (val) =>
        val?.toLowerCase() === "pre"
          ? "Prepaid"
          : val?.toLowerCase() === "post"
          ? "Postpaid"
          : val,
    },
    {
      title: "Finance image",
      render: (val) =>
        val?.miterType?.toLowerCase() === "post" ? (
          val?.status === 3 ? (
            <div>
              <BillImageUpload
                id={val?._id}
                onChange={(img) => handleImgChange(img as string, val?._id)}
                // showInput={}
              />
            </div>
          ) : val?.billFinanceImage ? (
            <div
              className={`w-[76px] h-[76px] bg-status-waiting-bg rounded-sm border border-others-secondary-border relative `}
            >
              <img
                className="w-full h-full object-cover rounded-sm"
                src={IMAGE_URL + val?.billFinanceImage}
                alt=""
              />
            </div>
          ) : null
        ) : null,
    },
    {
      title: "Regional image",
      dataIndex: "billRegionImage",
      render: (val) =>
        val ? (
          <div
            className={`w-[76px] h-[76px] bg-status-waiting-bg rounded-sm border border-others-secondary-border relative `}
          >
            <img
              className="w-full h-full object-cover rounded-sm"
              src={IMAGE_URL + val}
              alt=""
            />
          </div>
        ) : (
          "N/A"
        ),
    },
    {
      title: "Status",
      align: "center",
      dataIndex: "status",
      render: (val) => (
        <span
          className={`status
            ${
              val === 3
                ? "status-pending"
                : val === 4
                ? "status-done"
                : val === 2
                ? "status-info"
                : val === 5
                ? "status-waiting"
                : "status-danger"
            }`}
        >
          {val === 3
            ? "Pending"
            : val === 2
            ? "Verify"
            : val === 4
            ? "Paid"
            : val === 5
            ? "Settlement"
            : "Unpaid"}
        </span>
      ),
    },
    // {
    //   title: "Action",
    //   render: (val) => (
    //     <Space size="small">
    //       <Link to={``}>
    //         <Button icon={<ViewIcon />} />
    //       </Link>
    //       <Link to={``}>
    //         <Button icon={<EditIcon />}>
    //           <span className="text-others-accent relative -top-0.5">
    //             Recharge
    //           </span>{" "}
    //         </Button>
    //       </Link>
    //     </Space>
    //   ),
    // },
  ];

  const getData = useCallback(() => {
    // const data = {
    //   status: 2,
    // };

    const data: any = {
      status: { $in: [3, 5] },
      year: year?.year(),
    };

    if (checkValue && checkValue !== "All") {
      data.miterType = checkValue;
    }

    if (month) {
      data.month = month;
    }

    dispatch(
      getAllBillGenerateAsync({
        data,
        others: null,
      })
    );
  }, [checkValue, dispatch, month, year]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCheckSelect = (e: RadioChangeEvent) => {
    setCheckValue(e.target.value);
    handleSetFirstPage(true);
    setSelectedData([]);
  };

  const uploadedCount = (value: number, total: number) => {
    setUploadCount(value);
    setTotalCount(total);
  };

  function reRender(val: boolean) {
    if (val) {
      // getData();
      toggle();
    }
  }

  // const handleGenerate = () => {
  //   dispatch(
  //     requestToBillAsync({
  //       data: {
  //         paymentId: selectedData,
  //       },
  //       others: {
  //         reRender: generateRender,
  //       },
  //     })
  //   );
  // };

  function generateRender(val: boolean) {
    if (val) {
      getData();
      handleOpen({
        type: "BILL_SUCCESS",
      });
      setCheckValue("All");
      setSelectedData([]);
      setMonth(undefined);
    }
  }

  const handleDownloadExcel = async () => {
    handleExcelLoading(true);
    let excelData = [] as any[];

    await AXIOS.post(BASE_API.getAllBillGenerate, {
      status: { $in: [3, 5] },
    }).then((res) => {
      const responseData = res?.data?.getData as any[];

      responseData?.forEach((el) => {
        let data = {} as any;
        data["Meter Name"] = capitalize(el?.miterName);
        data["Meter Number"] = capitalize(el?.miterNumber);
        // data["Meter Token"] = capitalize(el?.miterNumber);
        data["Tower Name"] = capitalize(el?.tower?.towerName);
        data["Site Code"] = el?.tower?.siteCode;
        data["Month"] = capitalize(el?.month);
        data["Year"] = capitalize(el?.year);
        data["Total Bill Amount"] = el?.billAmount || 0;
        data["EPSC"] = el?.companyName?.toUpperCase();
        // data["Meter Number"] = el?.miterNumber;
        data["Meter Type"] =
          el?.miterType?.toLowerCase() === "pre"
            ? "Prepaid"
            : el?.miterType?.toLowerCase() === "post"
            ? "Postpaid"
            : el?.miterType;
        data["Status"] =
          el?.status === 3
            ? "Pending"
            : el?.status === 2
            ? "Verify"
            : el?.status === 4
            ? "Paid"
            : el?.status === 5
            ? "Settlement"
            : "Unpaid";

        excelData.push(data);
      });
    });

    downloadExcel(excelData);
  };

  const handleReload = () => {
    getData();
    setCheckValue("All");
    setMonth(undefined);
    setSelectedData([]);
  };

  useEffect(() => {
    if ((checkValue || month || year) && isFirstPage) {
      handlePagination(1, 10);
      handleSetFirstPage(false);
      // setCheckValue("All");
      // setMonth("");
      // setSelectedData([]);
    }
  }, [
    handlePagination,
    handleSetFirstPage,
    isFirstPage,
    checkValue,
    month,
    year,
  ]);

  const disableGenerateButton = () => {
    const postSelectedData = billGenerateData?.filter(
      (el) =>
        selectedData?.some((key) => key?._id === el?._id) &&
        el?.miterType === "post"
    );

    const selectedImage = postSelectedData?.filter((el) =>
      uploadedFile?.some((item) => item?.key === el?._id)
    );

    return postSelectedData?.length === 0 ||
      postSelectedData?.length === selectedImage?.length
      ? false
      : true;
  };

  return (
    <AdminLayout title="Pay Bill">
      {open?.type === "BILL_SUCCESS" ? (
        <RequestBillSuccessfully handleClose={toggle} open={open?.open} />
      ) : null}

      {postLoading && uploadCount && totalCount ? (
        <Progress
          className="fixed w-full left-0 right-0 -top-[7px] z-[99999999999999999999999999999999]"
          percent={percentage(uploadCount / 2, totalCount)}
          showInfo={false}
          success={{ strokeColor: "#384DDA" }}
        />
      ) : null}

      {open?.type === "IMPORT" && (
        <ImportExcel
          open={open.open}
          handleClose={toggle}
          reRender={reRender}
          uploadedCount={uploadedCount}
        />
      )}

      {open.type === "GENERATE_BILL" && (
        <GenerateBill
          open={open.open}
          data={selectedData}
          handleClose={toggle}
          reRender={generateRender}
          checkValue={checkValue}
        />
      )}

      <Subheader title="Pay Bill">
        <Space className="top_bar_input" size="middle">
          {/* <Input
            size="large"
            suffix={<SearchIcon />}
            placeholder="Search"
            onChange={handleInputChange}
          /> */}

          {/* {checkValue === "Prepaid Meter" ? (
            <IconButton
              className="upload-btn"
              nextIcon={<DownloadIcon />}
              size="large"
            >
              Upload Token
            </IconButton>
          ) : (
            <IconButton icon={<ReloadIcon />} size="large">
              Reload
            </IconButton>
          )} */}
          {/* <IconButton
            className="upload-btn"
            nextIcon={<DownloadIcon />}
            size="large"
            onClick={() =>
              handleOpen({
                type: "IMPORT",
              })
            }
          >
            Import Bill
          </IconButton> */}
          <IconButton icon={<ReloadIcon />} size="large" onClick={handleReload}>
            Reload
          </IconButton>
        </Space>
      </Subheader>

      <CustomTable<TableData<any>>
        loading={loading}
        // total={territoryCount}
        total={allBillGenerate?.length}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(billGenerateData)}
        // bordered={false}
        // isLimitRow={true}
        showTotalPage={true}
        handleLimit={handleLimit}
        tableClass="bill_generate_table"
        isPagination={false}
        tableTopChildren={
          <Space size="middle" className="flex justify-end">
            <Radio.Group
              disabled={updateLoading}
              onChange={handleCheckSelect}
              value={checkValue}
            >
              <Radio
                className="text-others-table-header text-base font-medium"
                value="All"
              >
                All
              </Radio>
              <Radio
                className="text-others-table-header text-base font-medium"
                value="post"
              >
                Postpaid Meter
              </Radio>
              <Radio
                className="text-others-table-header text-base font-medium"
                value="pre"
              >
                Prepaid Meter
              </Radio>
            </Radio.Group>

            <Select
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              value={month}
              options={monthList?.map((item: string) => ({
                label: item,
                value: item?.toLowerCase(),
              }))}
              placeholder="Select Month"
              onChange={(val) => {
                setMonth(val);
                handleSetFirstPage(true);
              }}
              className="w-[140px]"
            />

            <DatePicker
              rootClassName="date_select"
              size="large"
              onChange={(val) => {
                setYear(val);
                handleSetFirstPage(true);
              }}
              picker="year"
              value={year}
              allowClear={false}
            />

            <IconButton
              nextIcon={<DownloadIcon />}
              size="large"
              loading={excelLoading}
              onClick={handleDownloadExcel}
            >
              Export Excel
            </IconButton>

            <IconButton
              type="text"
              className="secondary-btn"
              icon={<PayBillIcon />}
              size="large"
              loading={updateLoading}
              disabled={!selectedData?.length || disableGenerateButton()}
              onClick={() =>
                handleOpen({
                  type: "GENERATE_BILL",
                })
              }
            >
              Approve to Bill Pay
            </IconButton>
            {/* {checkValue === "Prepaid Meter" && (
              <IconButton type="text" className="recharge-btn" size="large">
                Online Recharge
              </IconButton>
            )} */}
          </Space>
        }
        rowSelection={{
          type: "checkbox",
          // columnTitle: "Check",
          columnWidth: 60,
          onChange: (_, dataItem) => setSelectedData(dataItem),
          selectedRowKeys: selectedData?.map((el) => el?.key),
        }}
      />
    </AdminLayout>
  );
}

export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
