import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  // CommonOthers,
  ErrorMessage,
  Params,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";
// import {
//   GetElectricitySupplierData,
//   ElectricitySupplierData,
// } from "../../../../types/redux/admin/electricitySupplier";

// create a electricitySupplier
export const createElectricitySupplierAsync = createAsyncThunk<
  null,
  PostRequest<any, CommonOthers>
>(
  "admin-electricitySupplier/createElectricitySupplier",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createElectricitySupplier, data);
      reRender(true);
      toast("success", "ElectricitySupplier create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Electricity supplier create fail");
      return rejectWithValue(error);
    }
  }
);

// get all electricitySupplier
export const getAllElectricitySupplierAsync = createAsyncThunk<
  any[],
  Params<any>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-electricitySupplier/getAllElectricitySupplier",
  async ({ params }, { rejectWithValue }) => {
    try {
      const getData = await AXIOS.get(BASE_API.getAllElectricitySupplier, {
        params,
      });

      return getData.data?.getData as any[];
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get all electricitySupplier data fail");
      return rejectWithValue(error);
    }
  }
);

// // get single ElectricitySupplier
// export const getSingleElectricitySupplierAsync = createAsyncThunk<
//   GetElectricitySupplierData,
//   Params<GetSingleDataParams>,
//   {
//     rejectValue: ErrorMessage;
//   }
// >(
//   "admin-electricitySupplier/getSingleElectricitySupplier",
//   async ({ params }, { rejectWithValue }) => {
//     try {
//       const data = await AXIOS.get(
//         `${BASE_API.getSingleElectricitySupplier}/${params?.id}`
//       );

//       return data.data?.getData as GetElectricitySupplierData;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Get electricity supplier fail");
//       return rejectWithValue(error);
//     }
//   }
// );
