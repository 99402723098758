import { createSlice } from "@reduxjs/toolkit";

import { MiterInitialStatus } from "../../../../types/redux";
import { createMiterAsync, getAllMiterAsync } from "./miterAPI";

const initialState: MiterInitialStatus<any> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allMiter: [],
  // singleMiter: null,
  miterCount: 0,
};

const miterSlice = createSlice({
  name: "admin-miter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create miter
    builder
      .addCase(createMiterAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createMiterAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createMiterAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all miter
    builder
      .addCase(getAllMiterAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMiterAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allMiter = action.payload;
      })
      .addCase(getAllMiterAsync.rejected, (state) => {
        state.loading = false;
        state.allMiter = [];
      });
    // get single miter
    // builder
    //   .addCase(getSingleMiterAsync.pending, (state) => {
    //     state.viewLoading = true;
    //   })
    //   .addCase(getSingleMiterAsync.fulfilled, (state, action) => {
    //     state.viewLoading = false;
    //     state.singleMiter = action.payload;
    //   })
    //   .addCase(getSingleMiterAsync.rejected, (state) => {
    //     state.viewLoading = false;
    //     state.singleMiter = null;
    //   });
  },
});

export default miterSlice;
