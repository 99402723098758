export default function PayBillIcon() {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="-mb-0.5"
    >
      <path
        d="M4.75 10.6875H9.25M4.75 8.4375H9.25M9.25014 2.8125H12.0625C12.2117 2.8125 12.3548 2.87176 12.4602 2.97725C12.5657 3.08274 12.625 3.22582 12.625 3.375V15.1875C12.625 15.3367 12.5657 15.4798 12.4602 15.5852C12.3548 15.6907 12.2117 15.75 12.0625 15.75H1.9375C1.78832 15.75 1.64524 15.6907 1.53975 15.5852C1.43426 15.4798 1.375 15.3367 1.375 15.1875V3.375C1.375 3.22582 1.43426 3.08274 1.53975 2.97725C1.64524 2.87176 1.78832 2.8125 1.9375 2.8125H4.74985M4.1875 5.0625V4.5C4.1875 3.75408 4.48382 3.03871 5.01126 2.51126C5.53871 1.98382 6.25408 1.6875 7 1.6875C7.74592 1.6875 8.46129 1.98382 8.98874 2.51126C9.51618 3.03871 9.8125 3.75408 9.8125 4.5V5.0625H4.1875Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
