export default function FileIcon() {
  return (
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.09374 6.24997H14.8437C15.1753 6.24997 15.4932 6.11828 15.7276 5.88386C15.962 5.64944 16.0937 5.3315 16.0937 4.99999C16.0937 4.66847 15.962 4.35053 15.7276 4.11611C15.4932 3.88169 15.1753 3.75 14.8437 3.75H3.59374C2.24698 3.75 1.15521 4.86929 1.15521 6.25C1.15521 7.63071 2.24697 8.75 3.59374 8.75H14.9995C15.9941 8.75 16.9479 8.35491 17.6512 7.65165C18.3544 6.94839 18.7495 5.99456 18.7495 5C18.7495 4.00544 18.3544 3.05161 17.6512 2.34835C16.9479 1.64509 15.9941 1.25 14.9995 1.25H6.24953"
        stroke="#5E718D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
