export default function TrashIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mt-[3px]"
    >
      <path
        d="M8.68765 6.3125V10.8125M5.31265 6.3125V10.8125M13.1874 2.9375L0.812378 2.93751M12.0627 2.9375V13.625C12.0627 13.7742 12.0034 13.9173 11.8979 14.0227C11.7924 14.1282 11.6493 14.1875 11.5002 14.1875H2.50015C2.35097 14.1875 2.20789 14.1282 2.10241 14.0227C1.99692 13.9173 1.93765 13.7742 1.93765 13.625V2.9375M9.81265 2.9375V1.8125C9.81265 1.51413 9.69413 1.22798 9.48315 1.017C9.27217 0.806026 8.98602 0.6875 8.68765 0.6875H5.31265C5.01428 0.6875 4.72814 0.806026 4.51716 1.017C4.30618 1.22798 4.18765 1.51413 4.18765 1.8125V2.9375"
        stroke="#AFBACA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
