import { Button } from "antd";
import ThreeDot from "../../../assets/icons/ThreeDot";
import { numberShort } from "../../../utils/number";
import { capitalize } from "../../../utils/string";

type Props = {
  getSiteBill: any;
};

export default function SiteWiseBill({ getSiteBill }: Props) {
  return (
    <div className="rounded-md border border-status-info-bg border-solid">
      <div className="flex justify-between items-center p-4">
        <h5 className="text-others-table-header text-lg font-semibold leading-[30px]">
          Site Wise Bill
        </h5>
        <Button className="!border-none -mr-3" icon={<ThreeDot />} />
      </div>

      <div className="max-h-[500px] hover-scrollbar overflow-y-auto">
        <ul className="list-none p-4 !pt-0">
          {getSiteBill?.map((val: any) => (
            <li
              className="border-b border-solid border-t-0 border-x-0 border-status-info-bg pb-2 pt-1 last:pb-0 last:border-b-0"
              key={val?._id}
            >
              <div className="flex justify-between items-center mb-0.5">
                <span className="text-body text-base font-medium leading-7 block">
                  {capitalize(val?.towerName)}
                </span>
                <span className="text-right text-status-info-text text-base font-semibold leading-7 block">
                  {numberShort(val?.totalBill)}
                </span>
              </div>
              <span className="text-[#8797ae] text-sm font-medium block">
                Side Code: {val?.siteCode}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
