import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllTowerData,
  GetNetworkLogData,
  NPTowerInitialStatus,
} from "../../../../types/redux";
import {
  // createTowerAsync,
  getAllTowerAsync,
} from "./networkAPI";

const initialState: NPTowerInitialStatus<
  GetAllTowerData,
  GetNetworkLogData,
  any
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  viewLoading: false,
  allTowers: [],
  logNetworks: null,
  singleTower: null,
  towersCount: 0,
};

const networkSlice = createSlice({
  name: "admin-network",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create tower
    // builder
    //   .addCase(createTowerAsync.pending, (state) => {
    //     state.postLoading = true;
    //   })
    //   .addCase(createTowerAsync.fulfilled, (state) => {
    //     state.postLoading = false;
    //   })
    //   .addCase(createTowerAsync.rejected, (state) => {
    //     state.postLoading = false;
    //   });
    // get all towers
    builder
      .addCase(getAllTowerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTowerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allTowers = action.payload;
      })
      .addCase(getAllTowerAsync.rejected, (state) => {
        state.loading = false;
        state.allTowers = [];
      });
    // get users tower
    // builder
    //   .addCase(getCountTowerAsync.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(getCountTowerAsync.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.towersCount = action.payload;
    //   })
    //   .addCase(getCountTowerAsync.rejected, (state) => {
    //     state.loading = false;
    //     state.towersCount = 0;
    //   });
    // // get single tower
    // builder
    //   .addCase(getSingleTicketAsync.pending, (state) => {
    //     state.viewLoading = true;
    //   })
    //   .addCase(getSingleTicketAsync.fulfilled, (state, action) => {
    //     state.viewLoading = false;
    //     state.singleTower = action.payload;
    //   })
    //   .addCase(getSingleTicketAsync.rejected, (state) => {
    //     state.viewLoading = false;
    //     state.singleTower = null;
    //   });
    // // update tower
    // builder
    //   .addCase(updateTowerAsync.pending, (state) => {
    //     state.updateLoading = true;
    //   })
    //   .addCase(updateTowerAsync.fulfilled, (state) => {
    //     state.updateLoading = false;
    //   })
    //   .addCase(updateTowerAsync.rejected, (state) => {
    //     state.updateLoading = false;
    //   });
    // // get log view network
    // builder
    //   .addCase(networkLogViewAsync.pending, (state) => {
    //     state.viewLoading = true;
    //   })
    //   .addCase(networkLogViewAsync.fulfilled, (state, action) => {
    //     state.viewLoading = false;
    //     state.logNetworks = action.payload;
    //   })
    //   .addCase(networkLogViewAsync.rejected, (state) => {
    //     state.viewLoading = false;
    //     state.logNetworks = null;
    //   });
    // // feedback user
    // builder
    //   .addCase(sendFeedbackAsync.pending, (state) => {
    //     state.updateLoading = true;
    //   })
    //   .addCase(sendFeedbackAsync.fulfilled, (state) => {
    //     state.updateLoading = false;
    //   })
    //   .addCase(sendFeedbackAsync.rejected, (state) => {
    //     state.updateLoading = false;
    //   });
  },
});

export default networkSlice;
