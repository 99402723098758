import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllMiterAsync } from "../../../store/features/admin/miter/miterAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  CommonParams,
  GetTerritoryData,
  TableData,
} from "../../../types/redux";
import { stringToArray } from "../../../utils/array";
import { countRow } from "../../../utils/number";
import { capitalize } from "../../../utils/string";

export default function MeterInfo() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { limit, page, handlePagination } = useTable();
  const { allMiter, loading } = useAppSelector((state) => state.miter);
  const meterData = allMiter?.slice((page - 1) * limit, limit * page);
  const path = stringToArray(location.pathname, "/")?.[0];

  const columns: ColumnsType<TableData<any>> = [
    {
      title: "No.",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Meter name",
      dataIndex: "miterName",
      render: (val) => <span className="text-base">{capitalize(val)}</span>,
    },
    {
      title: "Meter type",
      dataIndex: "miterType",
      render: (val) =>
        val === "pre" ? "Prepaid" : val === "post" ? "Postpaid" : val,
    },
    {
      title: "Meter number",
      dataIndex: "miterNumber",
      render: (val) => val || "N/A",
    },
    {
      title: "Next payment day",
      dataIndex: "gapDay",
      render: (val) => val || "N/A",
    },
    {
      title: "Meter type",
      dataIndex: "miterType",
      render: (val) =>
        val === "pre" ? "Prepaid" : val === "post" ? "Postpaid" : val,
    },
    {
      title: "Site name & Code",
      dataIndex: "tower",
      render: (val) => (
        <div>
          <span className="font-semibold">{capitalize(val?.towerName)}</span>

          <br />
          <small className="text-xs font-normal leading-tight tracking-tight text-accent">
            Site Code: {val?.siteCode}
          </small>
        </div>
      ),
    },
    {
      title: "EPSC",
      dataIndex: "companyName",
      render: (val) => <span className="text-base">{val?.toUpperCase()}</span>,
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   render: (val: GetTerritoryData) => (
    //     <Space size="small">
    //       <Link to={`/admin/meter-info/view-meter-info/${val?._id}`}>
    //       <Button icon={<ViewIcon />} />
    //       </Link>
    //       <Link to={`/admin/meter-info/update-meter-info/${val?._id}`}>
    //       <Button icon={<EditIcon />} />
    //       </Link>
    //       <Button icon={<TrashIcon />} />
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllMiterAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  // useEffect(() => {
  //   dispatch(getCountTerritoryAsync());
  // }, [dispatch]);

  return (
    <AdminLayout title="Meter List">
      <Subheader title="Meter List">
        <Space size="middle">
          <Link to={`/${path}/meter-info/add-meter-info`}>
            <IconButton
              type="text"
              className="secondary-btn"
              icon={<AddIcon />}
            >
              Add New Meter
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<GetTerritoryData>>
        loading={loading}
        total={allMiter?.length}
        // total={territoryCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(meterData)}
        // bordered={false}
        // isPagination={false}
      />
    </AdminLayout>
  );
}
