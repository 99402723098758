import { Form, FormProps, Input, InputNumber, Select } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import Loader from "../../../components/common/Loader";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllAreaAsync,
  getAllRegionAsync,
  getSingleTerritoryAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TerritoryData } from "../../../types/redux";

const navLink = "/admin/tower-info";

export default function ManageTower() {
  const { id, viewId } = useParams();
  const [form] = Form.useForm();
  //   const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectRegion, setSelectRegion] = useState("");
  const [selectArea, setSelectArea] = useState("");
  const {
    postLoading,
    regions,
    areas,
    loading,
    updateLoading,
    singleTerritory,
    viewLoading,
    territorys,
  } = useAppSelector((state) => state.npGeo);

  const onFinish: FormProps<TerritoryData>["onFinish"] = (values) => {
    // if (id) {
    //   dispatch(
    //     updateTerritoryAsync({
    //       data: values,
    //       others: {
    //         reRender,
    //         id,
    //       },
    //     })
    //   );
    // } else {
    //   dispatch(
    //     createTerritoryAsync({
    //       data: values,
    //       others: {
    //         reRender,
    //       },
    //     })
    //   );
    // }
  };

  // redirect and re-render function
  //   const reRender = (val: boolean) => {
  //     if (val) navigate(navLink);
  //   };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
    dispatch(
      getAllAreaAsync({
        params: {
          region: selectRegion,
        },
      })
    );
  }, [dispatch, selectRegion]);

  useEffect(() => {
    if ((id || viewId) && singleTerritory && updateLoading === false) {
      setSelectRegion(singleTerritory?.region?._id);

      form.setFieldsValue({
        region: singleTerritory?.region?._id,
        area: singleTerritory?.area?._id,
        teritoryName: singleTerritory?.teritoryName,
      });
    }
  }, [form, id, singleTerritory, updateLoading, viewId]);

  useEffect(() => {
    if (id || viewId) {
      dispatch(getSingleTerritoryAsync({ params: { id: id || viewId || "" } }));
    }
  }, [dispatch, id, viewId]);

  const title = id
    ? "Update Tower/Site"
    : viewId
    ? "View Tower/Site"
    : "Add New Tower/Site";

  return (
    <AdminLayout title={title}>
      {viewLoading ? <Loader /> : null}
      <CustomForm<TerritoryData>
        onFinish={onFinish}
        title={title}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        hideBtn={viewId ? true : false}
        disabledSubmit={viewLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="siteName"
          label="Tower/Site Name"
          rules={[
            {
              required: true,
              message: "Please enter tower/site name",
            },
          ]}
        >
          <Input
            size="large"
            disabled={viewId ? true : false}
            placeholder="Name"
          />
        </Form.Item>
        <Form.Item
          name="siteName"
          label="Land Proprietor Contact no "
          rules={[
            {
              required: true,
              message: "Please enter proprietor contact no",
            },
            {
              pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
              message: "Please enter a valid Bangladeshi phone number",
            },
          ]}
        >
          <Input
            size="large"
            prefix={"BD "}
            placeholder="Mobile Number"
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="siteCode"
          label="Tower/Site Code"
          rules={[
            {
              required: true,
              message: "Please enter site code",
            },
          ]}
        >
          <Input
            size="large"
            disabled={viewId ? true : false}
            placeholder="Site Code"
          />
        </Form.Item>
        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            name="region"
            label="Region"
            rules={[
              {
                required: true,
                message: "Please select region",
              },
            ]}
          >
            <Select
              placeholder="Select region"
              size="large"
              allowClear
              showSearch
              loading={loading}
              filterOption={selectSearchOption}
              options={ArrayOption(regions, "regionName", "_id")}
              onChange={(val) => {
                setSelectRegion(val);
                setSelectArea("");
                form.setFieldValue("area", undefined);
                form.setFieldValue("teritoryName", undefined);
              }}
              disabled={viewId ? true : false}
            />
          </Form.Item>
          <Form.Item
            name="area"
            label="Area"
            rules={[
              {
                required: true,
                message: "Please select area",
              },
            ]}
          >
            <Select
              placeholder="Select area"
              size="large"
              allowClear
              showSearch
              loading={loading}
              filterOption={selectSearchOption}
              options={ArrayOption(areas, "areaName", "_id")}
              onChange={(val) => {
                setSelectArea(val);
                form.setFieldValue("teritory", undefined);
              }}
              disabled={!selectRegion || viewId ? true : false}
            />
          </Form.Item>

          <Form.Item
            name="teritory"
            label="Territory"
            rules={[
              {
                required: true,
                message: "Please select territory",
              },
            ]}
          >
            <Select
              placeholder="Select territory"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(territorys, "teritoryName", "_id")}
              disabled={!selectArea}
            />
          </Form.Item>

          <Form.Item
            name="geo"
            label="GEO"
            rules={[
              {
                required: true,
                message: "Please select territory",
              },
            ]}
          >
            <Select
              placeholder="Select GEO"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(territorys, "teritoryName", "_id")}
              disabled={!selectArea}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="latitude"
          label="Latitude"
          rules={[
            {
              required: true,
              message: "Please enter latitude",
            },
            {
              type: "number",
              min: 0,
              message: "Latitude can't be negative",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            size="large"
            placeholder="Enter latitude"
            disabled={viewId ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[
            {
              required: true,
              message: "Please enter longitude",
            },
            {
              type: "number",
              min: 0,
              message: "Longitude can't be negative",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            size="large"
            placeholder="Enter longitude"
            disabled={viewId ? true : false}
          />
        </Form.Item>

        <Form.Item
          name="radius"
          label="Radius (m)"
          rules={[
            {
              required: true,
              message: "Please enter radius no",
            },
            {
              type: "number",
              min: 0,
              message: "Radius can't be negative",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            size="large"
            placeholder="Enter radius no"
            disabled={viewId ? true : false}
          />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
