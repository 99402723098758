import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllAreaAsync,
  getCountAreaAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CommonParams, GetAreaData, TableData } from "../../../types/redux";
import { countRow } from "../../../utils/number";

export default function Area() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { areas, loading, areaCount } = useAppSelector((state) => state.npGeo);

  const columns: ColumnsType<TableData<GetAreaData>> = [
    {
      title: "No.",
      align: "center",
      width: 130,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Area Name",
      dataIndex: "areaName",
    },
    {
      title: "Region Name",
      render: (val: GetAreaData) => val?.region?.regionName,
    },
    {
      title: "Action",
      align: "center",
      render: (val: GetAreaData) => (
        <Space size="small">
          <Link to={`/admin/geo-info/area/view-area/${val?._id}`}>
            <Button icon={<ViewIcon />} />
          </Link>
          <Link to={`/admin/geo-info/area/update-area/${val?._id}`}>
            <Button icon={<EditIcon />} />
          </Link>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllAreaAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(getCountAreaAsync());
  }, [dispatch]);

  return (
    <AdminLayout title="Area List">
      <Subheader title="Area List">
        <Space size="middle">
          <Link to="/admin/geo-info/area/add-area">
            <IconButton
              type="text"
              className="secondary-btn"
              icon={<AddIcon />}
            >
              Add Area
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<GetAreaData>>
        loading={loading}
        total={areaCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(areas)}
        // bordered={false}
      />
    </AdminLayout>
  );
}
