import { HTMLAttributes, ReactNode } from "react";

type Props = {
  title?: string;
  subTitle?: string;
  children?: ReactNode;
  className?: string;
} & HTMLAttributes<HTMLDivElement>;

export default function Subheader({
  title,
  subTitle,
  children,
  className = "",
  ...rest
}: Props) {
  return (
    <div
      className={`py-2 justify-between items-center flex flex-wrap gap-3 sub_header ${className}`}
      {...rest}
    >
      <div>
        <h3 className="text-others-table-header text-2xl font-semibold title">
          {title}
        </h3>
        {subTitle ? (
          <h6 className="text-accent text-base font-medium mt-1">{subTitle}</h6>
        ) : null}
      </div>
      {children && <div className="ml-auto">{children}</div>}
    </div>
  );
}
