import { Select } from "antd";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { selectSearchOption } from "../../../helpers/siteConfig";
import { numberShort } from "../../../utils/number";
import { capitalize } from "../../../utils/string";
import Subheader from "../Subheader";

type Props = {
  monthlyBill: any[];
};

export default function ExpenseStatistics({ monthlyBill }: Props) {
  const [load, setLoad] = useState(true);
  const [select, setSelect] = useState("Monthly");

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  const series = [
    {
      data: monthlyBill?.map((el) => el?.total),
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return (val || 0) + "";
        },
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
    colors: ["#9CA5FD", "#FFE176"],
    plotOptions: {
      bar: {
        columnWidth: 28,
        distributed: true,
        borderRadius: 3,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      borderColor: "#d7dee8",
      strokeDashArray: 5,
    },
    xaxis: {
      categories: monthlyBill?.map((el) => capitalize(el?._id?.slice(0, 3))),
      labels: {
        style: {
          colors: "#afbaca",
          fontSize: "12px",
          fontFamily: "Lato, sans-serif",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#afbaca",
          fontSize: "12px",
          fontFamily: "Lato, sans-serif",
        },
        formatter: (val) => {
          return numberShort(val);
        },
      },
    },
  };

  useEffect(() => {
    const foreignObjectElement =
      document.getElementsByTagName("foreignObject")[0];
    if (foreignObjectElement) {
      foreignObjectElement.setAttribute("width", "100%");
    }
  }, []);

  return (
    <div className="rounded-md border border-status-info-bg border-solid p-5">
      <Subheader title="Expense Statistics">
        <Select
          size="middle"
          filterOption={selectSearchOption}
          value={select}
          rootClassName="select_gray"
          disabled
          options={[
            {
              label: "Monthly",
              value: "Monthly",
            },
            {
              label: "Yearly",
              value: "Yearly",
            },
          ]}
          onChange={(val) => setSelect(val)}
          className="min-w-[107px]"
        />
      </Subheader>

      {!load ? (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={450}
          width={"100%"}
        />
      ) : null}
    </div>
  );
}
