import { Button, ButtonProps, Form, FormProps, Space } from "antd";
import { HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import LeftArrowIcon from "../../assets/icons/LeftArrowIcon";
import Subheader from "../ui/Subheader";
import IconButton from "./IconButton";

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  title: string;
  formTitle?: string;
  backLink: string;
  submitBtnName?: string;
  disabled?: boolean;
  disabledSubmit?: boolean;
  onFinish: (props: T) => void;
  className?: string;
  children: ReactNode;
  formProps?: FormProps;
  buttonProps?: ButtonProps;
  isUpdate?: boolean;
  hideBtn?: boolean;
}

export default function CustomForm<T>({
  title,
  formTitle,
  backLink,
  isUpdate = false,
  submitBtnName = isUpdate ? "Update Now" : "Add Now",
  onFinish,
  className = "",
  children,
  disabled = false,
  disabledSubmit = false,
  hideBtn = true,
  formProps,
  buttonProps,
  ...rest
}: Props<T>) {
  return (
    <section
      {...rest}
      className={`shadow-sm bg-white rounded-lg pb-7 ${className}`}
    >
      <Subheader title={title}>
        <Space size="middle">
          <Link to={backLink}>
            <IconButton
              className="!border-others-border !text-sm !font-normal !rounded-lg !border !text-body !bg-transparent"
              type="default"
              icon={<LeftArrowIcon />}
            >
              <span>Back</span>
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <div className="mt-14">
        <div className="max-w-xl mx-auto p-6 bg-white rounded-lg shadow border border-others-border">
          <h5 className="text-lg font-semibold text-header mb-5">
            {formTitle}
          </h5>
          <Form
            className="custom-form hide_star_mark"
            layout="vertical"
            onFinish={onFinish}
            size="large"
            {...formProps}
          >
            {children}
            {!hideBtn ? (
              <div className="w-full justify-between flex gap-4 pt-3">
                <Link
                  className="block w-full cancel_btn"
                  to={disabled ? "" : backLink}
                >
                  <Button disabled={disabled} block size="large">
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="primary"
                  disabled={disabledSubmit || disabled}
                  size="large"
                  htmlType="submit"
                  block
                  loading={disabled}
                  {...buttonProps}
                >
                  {submitBtnName}
                </Button>
              </div>
            ) : null}
          </Form>
        </div>
      </div>
    </section>
  );
}
