import { ReactNode } from "react";
import LOGO from "../assets/images/main-logo.svg";
import AppLayout from "./AppLayout";

type Props = {
  title?: string;
  children: ReactNode;
};

export default function LoginLayout({
  title = "Welcome back to eBill. Please Sign in to continue.",
  children,
}: Props) {
  return (
    <AppLayout title="Login">
      <div className="h-screen">
        {/* grid md:grid-cols-2  */}
        <div className="h-full">
          {/* <div
            className={`hidden relative h-full text-center md:flex items-center justify-center ${bgColor}`}
          >
            <img className="h-52" src={LoginImage} alt="" />
            <img
              className="h-52 absolute top-0 opacity-10"
              src={LogoTop}
              alt=""
            />
            <img
              className="h-52 absolute bottom-0 opacity-10"
              src={LogoBottom}
              alt=""
            />
          </div> */}

          <div className="p-6 lg:p-20 flex justify-center items-center h-full">
            <div className="mx-auto !max-w-[368px] w-full bg-[#F9FAFB]">
              <div className="flex items-center shadow p-6 rounded-2xl">
                <div className="w-full">
                  <div className="text-center mb-6">
                    <div className="text-center mb-4">
                      <img className="h-[47.48px]" src={LOGO} alt="Logo" />
                    </div>
                    <h3 className="text-body mb-2 text-[26px] font-semibold leading-10">
                      Welcome Back!
                    </h3>
                    <p className="text-center text-accent text-sm font-normal leading-normal">
                      {title}
                    </p>
                  </div>

                  <div>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
