import { ReactNode } from "react";
import EmailUserIcon from "../../../assets/icons/EmailUserIcon";
import SystemAdminIcon from "../../../assets/icons/SystemAdminIcon";
import { IMG } from "../../../helpers/siteConfig";
import { useAppSelector } from "../../../store/store";
import { capitalize } from "../../../utils/string";
import Image from "../../common/Image";

export default function UserInfo() {
  const { login, siteRole } = useAppSelector((state) => state?.login);

  return (
    <div className="rounded-md border border-[#f0f3f9] border-solid">
      <div className="h-16 p-4 bg-[#f0f3f9] rounded-t-md">
        <Image
          width={70}
          height={70}
          rounded="full"
          src={IMG}
          style={{
            border: "2px solid #fff",
          }}
        />
      </div>
      <div className="p-4 pt-8">
        <h4 className="text-body text-lg font-semibold">
          {siteRole === "FINANCE"
            ? capitalize(login?.fullName)
            : `${login?.firstName} ${login?.lastName}`}
        </h4>
        <div className="pl-1.5 pr-2 py-1 bg-status-info-bg rounded-md justify-center items-center gap-2.5 inline-flex mt-1">
          <div className="justify-start items-center gap-1.5 flex">
            <SystemAdminIcon />
            <div className="text-status-info-text text-sm font-medium">
              {siteRole === "FINANCE" ? login?.role : "System Admin"}
            </div>
          </div>
        </div>
      </div>

      <ul className="list-none -mt-1">
        {/* <UserInfoData icon={<UserIcon />} title="User ID" value="A234567" />
        <UserInfoData
          icon={<PhoneIcon />}
          title="Mobile Number"
          value="+8801736398503"
        /> */}
        <UserInfoData
          icon={<EmailUserIcon />}
          title="Email Address"
          value={login?.email || ""}
        />
      </ul>
    </div>
  );
}

type UserInfoProps = {
  icon: ReactNode;
  title: string;
  value: string;
};

const UserInfoData = ({ icon, title, value }: UserInfoProps) => (
  <li className="flex items-center gap-3 border-t border-solid border-b-0 border-x-0 border-status-info-bg py-2.5 px-4 font-roboto">
    {icon}
    <div className="flex flex-col justify-between">
      <span className="text-accent !text-sm !font-medium">{title}</span>
      <strong className="text-others-table-header !text-base !font-medium">
        {value}
      </strong>
    </div>
  </li>
);
