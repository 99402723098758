import { Image as AntImage } from "antd";
import { ImageProps } from "antd/es/image";
import { StyleHTMLAttributes } from "react";

type Props = {
  width?: number | string;
  src: string;
  placeholder?: string;
  preview?: boolean;
  rounded?: string | number | "full";
  style?: StyleHTMLAttributes<"div">;
} & ImageProps;

const Image: React.FC<Props> = ({
  src,
  placeholder = src,
  width = 200,
  preview = false,
  rounded = 0,
  style,
  ...rest
}) => {
  return (
    <AntImage
      width={width}
      preview={preview}
      src={src}
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: rounded === "full" ? "50%" : rounded,
        ...style,
      }}
      placeholder={
        <AntImage
          preview={false}
          src={placeholder}
          width={width}
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: rounded === "full" ? "50%" : rounded,
            ...style,
          }}
        />
      }
      {...rest}
    />
  );
};

export default Image;
