import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  // CommonOthers,
  ErrorMessage,
  GetAllTowerData,
  GetAllTowerParams,
  // GetNetworkLogData,
  // GetSingleDataParams,
  // Params,
  PostRequest,
} from "../../../../types/redux";

// create a tower
// export const createTowerAsync = createAsyncThunk<
//   null,
//   PostRequest<TowerData, CommonOthers>
// >(
//   "np-network/createTower",
//   async ({ data, others: { reRender } }, { rejectWithValue }) => {
//     try {
//       await AXIOS.post(BASE_API.createTower, data);
//       reRender(true);
//       toast("success", "Tower create successfully");
//       return null;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Tower create fail");
//       return rejectWithValue(error);
//     }
//   }
// );

// get all users
export const getAllTowerAsync = createAsyncThunk<
  GetAllTowerData[],
  PostRequest<any, GetAllTowerParams>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "admin-network/getAllTower",
  async ({ data, others }, { rejectWithValue }) => {
    try {
      const getData = await AXIOS.post(BASE_API.getAllTower, data, {
        params: others,
      });

      return getData.data?.getData as GetAllTowerData[];
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Get all network data fail");
      return rejectWithValue(error);
    }
  }
);

// get count all tower
// export const getCountTowerAsync = createAsyncThunk<
//   number,
//   Params<any>,
//   {
//     rejectValue: ErrorMessage;
//   }
// >("np-network/getCountTower", async ({ params }, { rejectWithValue }) => {
//   try {
//     const getData = await AXIOS.post(BASE_API.getCountTower, params);
//     return getData.data?.getData?.[0]?.totalData || 0;
//   } catch (err) {
//     const errors = err as AxiosError;
//     const error = errors.response?.data as ErrorMessage;
//     toast("error", error?.message || "Get tower count data fail");
//     return rejectWithValue(error);
//   }
// });

// get single Ticket
// export const getSingleTicketAsync = createAsyncThunk<
//   any,
//   Params<GetSingleDataParams>,
//   {
//     rejectValue: ErrorMessage;
//   }
// >("np-network/getSingleTicket", async ({ params }, { rejectWithValue }) => {
//   try {
//     const data = await AXIOS.get(`${BASE_API.getSingleTicket}/${params?.id}`);

//     return data.data?.getData as any;
//   } catch (err) {
//     const errors = err as AxiosError;
//     const error = errors.response?.data as ErrorMessage;
//     toast("error", error?.message || "Get tower fail");
//     return rejectWithValue(error);
//   }
// });

// // update a tower
// export const updateTowerAsync = createAsyncThunk<
//   null,
//   PostRequest<TowerData, CommonOthers>
// >(
//   "np-network/updateTower",
//   async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
//     try {
//       await AXIOS.patch(`${BASE_API.updateTower}/${id}`, data);

//       reRender(true);
//       toast("success", "Tower update successfully");
//       return null;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Tower update fail");
//       return rejectWithValue(error);
//     }
//   }
// );

// // create a tower
// export const createTowerTicketAsync = createAsyncThunk<
//   null,
//   PostRequest<TicketGenerateData, TicketGenerateOthers>
// >(
//   "np-network/createTowerTicket",
//   async ({ data, others: { reRender, successDone } }, { rejectWithValue }) => {
//     try {
//       const ticket = await AXIOS.post(BASE_API.createTowerTicket, data);
//       reRender(true);
//       successDone(ticket?.data?.getData);
//       toast("success", "Ticket create successfully");
//       return null;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Ticket create fail");
//       return rejectWithValue(error);
//     }
//   }
// );

// // send feedback
// export const sendFeedbackAsync = createAsyncThunk<
//   null,
//   PostRequest<TicketFeedbackData, CommonOthers>
// >(
//   "np-network/sendFeedback",
//   async ({ data, others: { reRender, id } }, { rejectWithValue }) => {
//     try {
//       await AXIOS.patch(`${BASE_API.sendFeedback}/${id}`, data);
//       reRender(true);
//       toast("success", "Feedback send successfully");
//       return null;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Feedback send fail");
//       return rejectWithValue(error);
//     }
//   }
// );

// // send network log view
// export const networkLogViewAsync = createAsyncThunk<
//   GetNetworkLogData,
//   Params<GetSingleDataParams>,
//   {
//     rejectValue: ErrorMessage;
//   }
// >("np-network/networkLogView", async ({ params }, { rejectWithValue }) => {
//   try {
//     const data = await AXIOS.get(`${BASE_API.networkLogView}/${params?.id}`);

//     return data.data?.getData as GetNetworkLogData;
//   } catch (err) {
//     const errors = err as AxiosError;
//     const error = errors.response?.data as ErrorMessage;
//     toast("error", error?.message || "Get log data fail");
//     return rejectWithValue(error);
//   }
// });

// // tower ticket notification
// export const towerTicketNotificationAsync = createAsyncThunk<
//   null,
//   PostRequest<TicketNotificationData, null>
// >(
//   "np-network/towerTicketNotification",
//   async ({ data }, { rejectWithValue }) => {
//     try {
//       await AXIOS.post(BASE_API.towerTicketNotification, data);
//       // reRender(true);
//       // toast("success", "Ticket create successfully");
//       return null;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       toast("error", error?.message || "Notification send fail");
//       return rejectWithValue(error);
//     }
//   }
// );
