export default function SystemAdminIcon() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00016 8.10465C7.32983 7.96476 8.6705 7.96476 10.0002 8.10465M2.82071 10.2508C2.83629 10.3165 2.86502 10.3784 2.90515 10.4327C2.94527 10.4871 2.99598 10.5327 3.05422 10.567C3.11245 10.6012 3.17701 10.6233 3.244 10.6319C3.311 10.6405 3.37905 10.6355 3.44405 10.6172C6.42463 9.79422 9.57245 9.79392 12.5532 10.6163C12.6182 10.6346 12.6862 10.6396 12.7532 10.631C12.8201 10.6224 12.8847 10.6003 12.9429 10.5661C13.0011 10.5319 13.0518 10.4863 13.0919 10.432C13.132 10.3776 13.1608 10.3158 13.1764 10.2501L14.7694 3.48029C14.7908 3.38966 14.7864 3.29488 14.7567 3.20661C14.7271 3.11835 14.6734 3.04012 14.6017 2.98072C14.53 2.92132 14.4431 2.88313 14.3509 2.87043C14.2587 2.85773 14.1647 2.87104 14.0797 2.90885L10.9182 4.31396C10.804 4.36469 10.675 4.37086 10.5565 4.33124C10.438 4.29161 10.3387 4.20908 10.278 4.09987L8.43725 0.786476C8.39394 0.708529 8.33058 0.643581 8.25373 0.598361C8.17688 0.553142 8.08933 0.529297 8.00017 0.529297C7.911 0.529297 7.82345 0.553142 7.7466 0.598361C7.66975 0.643581 7.60639 0.708529 7.56309 0.786476L5.72231 4.09987C5.66165 4.20908 5.56229 4.29161 5.44382 4.33123C5.32534 4.37086 5.19633 4.36469 5.08217 4.31396L1.9202 2.90864C1.83514 2.87083 1.74121 2.85752 1.64899 2.87021C1.55678 2.88289 1.46993 2.92107 1.39823 2.98044C1.32654 3.03981 1.27284 3.11802 1.24319 3.20625C1.21353 3.29449 1.20909 3.38925 1.23038 3.47987L2.82071 10.2508Z"
        stroke="#4A72FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
