import SuccessIcon from "../../assets/icons/SuccessIcon";
import { CustomModalProps } from "../../types";
import CustomModal from "../common/CustomModal";

export default function RequestBillSuccessfully({ ...rest }: CustomModalProps) {
  return (
    <CustomModal width={300} closeIcon={null} {...rest}>
      <div className="w-[300px] h-[220px] pl-[77px] pr-[76px] pt-[66px] pb-[65px] bg-white rounded-lg justify-center items-center inline-flex">
        <div className="self-stretch flex-col justify-start items-center gap-4 inline-flex">
          <div className="w-[54px] h-[54px] relative">
            <div className="w-[42px] h-[42px] left-[6px] top-[6px] absolute flex items-center justify-center bg-primary-np rounded-full">
              <SuccessIcon />
            </div>
            <div className="w-[30px] h-[30px] pl-[5.62px] pr-[4.69px] py-[8.44px] left-[12px] top-[12px] absolute justify-center items-center inline-flex" />
          </div>
          <div className="text-primary-np text-base font-medium">
            Successfully Done!
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
