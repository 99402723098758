import { Space } from "antd";
import moment from "moment";
import { useCallback, useEffect } from "react";
import ReloadIcon from "../../assets/icons/ReloadIcon";
import TOP1 from "../../assets/images/dashboard/top-1.svg";
import TOP2 from "../../assets/images/dashboard/top-2.svg";
import TOP3 from "../../assets/images/dashboard/top-3.svg";
import TOP4 from "../../assets/images/dashboard/top-4.svg";
import IconButton from "../../components/common/IconButton";
import Loader from "../../components/common/Loader";
import ExpenseStatistics from "../../components/ui/dashboard/ExpenseStatistics";
import SiteWiseBill from "../../components/ui/dashboard/SiteWiseBill";
import TopCard from "../../components/ui/dashboard/TopCard";
import AdminLayout from "../../layouts/AdminLayout";
import {
  getMonthYearBillAsync,
  getSiteBillAsync,
} from "../../store/features/admin/dashboard/dashboardAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { numberShort } from "../../utils/number";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const { monthYearBill, getSiteBill, loading } = useAppSelector(
    (state) => state.adminDashboard
  );
  const monthlyBill = fillMissingMonths(monthYearBill);

  const getData = useCallback(() => {
    dispatch(getMonthYearBillAsync(null));
    dispatch(getSiteBillAsync(null));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const thisMonth = getMonthData(monthlyBill, true);
  const prevMonth = getMonthData(monthlyBill, false);
  const totalThisYearData = calculateYearlyTotals(monthlyBill);

  const topBarData = [
    {
      title: "This Month Expense",
      amount: numberShort(thisMonth?.total),
      billPaid: thisMonth?.totalBill,
      color: "#4a71ff",
      bg: TOP1,
    },
    {
      title: "Previous Month Expense",
      amount: numberShort(prevMonth?.total),
      billPaid: prevMonth?.totalBill,
      color: "#81c415",
      bg: TOP2,
    },
    {
      title: "This Year Expense",
      amount: numberShort(totalThisYearData?.total),
      billPaid: totalThisYearData?.totalBill,
      color: "#7d88f4",
      bg: TOP3,
    },
    {
      title: "Previous Years Expense",
      amount: 0,
      billPaid: 0,
      color: "#2fd180",
      bg: TOP4,
    },
  ];

  const handleReload = () => {
    getData();
  };

  return (
    <AdminLayout
      title="Dashboard"
      headerTitle="Dashboard"
      className="dashboard"
      headerChildren={
        <Space size="middle">
          <IconButton icon={<ReloadIcon />} size="large" onClick={handleReload}>
            Reload
          </IconButton>
        </Space>
      }
    >
      {loading ? (
        <div className="h-28">
          <Loader />
        </div>
      ) : null}

      <section className="mt-6">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          {topBarData?.map((el, idx) => (
            <TopCard {...el} key={el?.title + idx} />
          ))}
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-6">
          <div className="md:col-span-3">
            <ExpenseStatistics monthlyBill={monthlyBill} />
          </div>
          <SiteWiseBill getSiteBill={getSiteBill} />
        </div>
      </section>
    </AdminLayout>
  );
}

function fillMissingMonths(data: any) {
  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Create a map from the input data for quick lookup
  const dataMap = data.reduce((map: any, item: any) => {
    map[item?._id?.toLowerCase()] = item;
    return map;
  }, {});

  // Create an array with all months, filling in missing ones
  const result = allMonths.map((month) => {
    const lowerMonth = month.toLowerCase();
    return (
      dataMap[lowerMonth] || {
        _id: month?.toLowerCase(),
        total: 0,
        totalBill: 0,
      }
    );
  });

  return result;
}

function getMonthData(data: any, current: boolean) {
  const month = current
    ? moment().format("MMMM").toLowerCase()
    : moment().subtract(1, "months").format("MMMM").toLowerCase();

  const monthData = data?.find(
    (item: any) => item?._id.toLowerCase() === month
  );
  return monthData || { _id: month, total: 0, totalBill: 0 };
}

function calculateYearlyTotals(data: any) {
  const allMonths = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let totalSum = 0;
  let totalBillSum = 0;

  // Iterate through all months
  allMonths.forEach((month) => {
    // Find the corresponding month data
    const monthData = data.find(
      (item: any) => item?._id.toLowerCase() === month?.toLowerCase()
    );

    // Add the totals and totalBill for this month, or add 0 if the month is missing
    totalSum += monthData ? monthData.total : 0;
    totalBillSum += monthData ? monthData.totalBill : 0;
  });

  return {
    total: totalSum,
    totalBill: totalBillSum,
  };
}
