export default function ViewIcon() {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ml-[1px] mt-[5px]"
    >
      <path
        d="M9 0.937012C3.375 0.937012 1.125 6.00006 1.125 6.00006C1.125 6.00006 3.375 11.062 9 11.062C14.625 11.062 16.875 6.00006 16.875 6.00006C16.875 6.00006 14.625 0.937012 9 0.937012Z"
        stroke="#AFBACA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.8126C10.5533 8.8126 11.8125 7.5534 11.8125 6.0001C11.8125 4.4468 10.5533 3.1876 9 3.1876C7.4467 3.1876 6.1875 4.4468 6.1875 6.0001C6.1875 7.5534 7.4467 8.8126 9 8.8126Z"
        stroke="#AFBACA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
