import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { getAllElectricitySupplierAsync } from "../../../store/features/admin/electricitySupplier/electricitySupplierAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CommonParams, TableData } from "../../../types/redux";
import { countRow } from "../../../utils/number";

export default function ElectricitySupplier() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { allElectricitySupplier, loading } = useAppSelector(
    (state) => state.electricitySupplier
  );
  const electricitySupplierData = allElectricitySupplier?.slice(
    (page - 1) * limit,
    limit * page
  );

  const columns: ColumnsType<TableData<any>> = [
    {
      title: "No.",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "EPSC Name",
      dataIndex: "companyName",
      render: (val) => val?.toUpperCase(),
    },
    {
      title: "Region",
      dataIndex: "region",
      render: (val) => val?.regionName,
    },
    {
      title: "Total Meters",
      render: (val) => (val?.pre || 0) + (val?.post || 0),
    },
    {
      title: "Prepaid Meters",
      dataIndex: "pre",
      render: (val) => val || 0,
    },
    {
      title: "Postpaid Meters",
      dataIndex: "post",
      render: (val) => val || 0,
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   render: (val: GetTerritoryData) => (
    //     <Space size="small">
    //       <Link to={`/admin/electricity-supplier/view-supplier/${val?._id}`}>
    //       <Button icon={<ViewIcon />} />
    //       </Link>
    //       <Link to={`/admin/electricity-supplier/update-supplier/${val?._id}`}>
    //       <Button icon={<EditIcon />} />
    //       </Link>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllElectricitySupplierAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  // useEffect(() => {
  //   dispatch(getCountTerritoryAsync());
  // }, [dispatch]);

  return (
    <AdminLayout title="Electricity Supplier Company List">
      <Subheader title="Electricity Supplier Company List">
        <Space size="middle">
          <Link to="/admin/electricity-supplier/add-supplier">
            <IconButton
              type="text"
              className="secondary-btn"
              icon={<AddIcon />}
            >
              Add New Supplier
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<any>>
        loading={loading}
        total={allElectricitySupplier?.length}
        // total={territoryCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(electricitySupplierData)}
        // bordered={false}
        // isPagination={false}
      />
    </AdminLayout>
  );
}
