import { lazy } from "react";

import BillGenerate from "../pages/admin/BillGenerate/BillGenerate";
import AdminDashboard from "../pages/admin/Dashboard";
import ElectricitySupplier from "../pages/admin/ElectricitySupplier/ElectricitySupplier";
import ManageElectricitySupplier from "../pages/admin/ElectricitySupplier/ManageElectricitySupplier";
import Area from "../pages/admin/GeoInfo/Area";
import ManageArea from "../pages/admin/GeoInfo/ManageArea";
import ManageRegion from "../pages/admin/GeoInfo/ManageRegion";
import ManageTerritory from "../pages/admin/GeoInfo/ManageTerritory";
import Region from "../pages/admin/GeoInfo/Region";
import Territory from "../pages/admin/GeoInfo/Territory";
import AdminLogin from "../pages/admin/Login";
import ManageMeterInfo from "../pages/admin/MeterInfo/ManageMeterInfo";
import MeterInfo from "../pages/admin/MeterInfo/MeterInfo";
import Settings from "../pages/admin/Settings/Settings";
import ManageTower from "../pages/admin/Tower/ManageTower";
import Tower from "../pages/admin/Tower/Tower";
import RegionalLogin from "../pages/regional/Login";
// import FinanceDashboard from "../pages/finance/Dashboard";
import FinanceLogin from "../pages/finance/Login";
import PayBill from "../pages/finance/PayBill/PayBill";
// import FinanceSettings from "../pages/finance/Settings/Settings";
import { RouteType } from "../types";
const NotFound = lazy(() => import("../pages/NotFound"));

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "/admin/dashboard",
    element: AdminDashboard,
    roles: ["ADMIN"],
  },
  // GEO Information start
  {
    path: "/admin/geo-info/region",
    element: Region,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/region/add-region",
    element: ManageRegion,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/region/update-region/:id",
    element: ManageRegion,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/region/view-region/:viewId",
    element: ManageRegion,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/area",
    element: Area,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/area/add-area",
    element: ManageArea,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/area/update-area/:id",
    element: ManageArea,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/area/view-area/:viewId",
    element: ManageArea,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/geo-info/territory",
    element: Territory,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/territory/add-territory",
    element: ManageTerritory,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/territory/update-territory/:id",
    element: ManageTerritory,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/geo-info/territory/view-territory/:viewId",
    element: ManageTerritory,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/electricity-supplier",
    element: ElectricitySupplier,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/electricity-supplier/add-supplier",
    element: ManageElectricitySupplier,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/electricity-supplier/update-supplier/:id",
    element: ManageElectricitySupplier,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/electricity-supplier/view-supplier/:viewId",
    element: ManageElectricitySupplier,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/tower-info",
    element: Tower,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/tower-info/add-tower",
    element: ManageTower,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/tower-info/update-supplier/:id",
    element: ManageTower,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/tower-info/view-supplier/:viewId",
    element: ManageTower,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/meter-info",
    element: MeterInfo,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/meter-info/add-meter-info",
    element: ManageMeterInfo,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/meter-info/update-meter-info/:id",
    element: ManageMeterInfo,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/meter-info/view-meter-info/:viewId",
    element: ManageMeterInfo,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/bill-generate",
    element: BillGenerate,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/settings",
    element: Settings,
    roles: ["ADMIN"],
  },

  // ====== Finance Panel ======
  {
    path: "/finance/dashboard",
    element: AdminDashboard,
    roles: ["FINANCE"],
  },
  {
    path: "/finance/pay-bill",
    element: PayBill,
    roles: ["FINANCE"],
  },
  // {
  //   path: "/finance/settings",
  //   element: FinanceSettings,
  //   roles: ["FINANCE"],
  // },
  // ====== Regional Panel ======
  {
    path: "/regional/dashboard",
    element: AdminDashboard,
    roles: ["REGIONAL"],
  },
  {
    path: "/regional/meter-info",
    element: MeterInfo,
    roles: ["REGIONAL"],
  },
  {
    path: "/regional/meter-info/add-meter-info",
    element: ManageMeterInfo,
    roles: ["REGIONAL"],
  },
  {
    path: "/regional/meter-info/update-meter-info/:id",
    element: ManageMeterInfo,
    roles: ["REGIONAL"],
  },
  {
    path: "/regional/meter-info/view-meter-info/:viewId",
    element: ManageMeterInfo,
    roles: ["REGIONAL"],
  },
  {
    path: "/regional/bill-generate",
    element: BillGenerate,
    roles: ["REGIONAL"],
  },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "/admin/login",
    element: AdminLogin,
  },
  {
    path: "/finance/login",
    element: FinanceLogin,
  },
  {
    path: "/regional/login",
    element: RegionalLogin,
  },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];
