type Props = {
  bg: string;
  title: string;
  color: string;
  amount: string | number;
  billPaid: number;
};

export default function TopCard(props: Props) {
  return (
    <article
      className="p-5 rounded-lg bg-no-repeat bg-cover w-full h-full"
      style={{ backgroundImage: `url(${props?.bg})` }}
    >
      <h6 className="text-body text-base font-medium leading-7">
        {props?.title}
      </h6>
      <h4
        className="text-[26px] font-bold leading-10 mt-2.5"
        style={{ color: props?.color }}
      >
        {props?.amount}
      </h4>
      <small className="text-accent text-base font-medium leading-7 block mt-0.5">
        {props?.billPaid} Bill Paid
      </small>
    </article>
  );
}
