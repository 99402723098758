import { Button } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useState } from "react";
import * as XLSX from "xlsx";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
// import { uploadPaymentAsync } from "../../../store/features/admin/payment/paymentAPI";
import moment from "moment";
import useExcelDownload from "../../../hooks/useExcelDownload";
import { uploadBillAsync } from "../../../store/features/admin/billGenerate/billGenerateAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { stringToArray } from "../../../utils/array";
import { capitalize, sliceString } from "../../../utils/string";
import CustomModal from "../../common/CustomModal";

type Props = {
  open: boolean;
  handleClose: () => void;
  data?: any;
  reRender: (val: boolean) => void;
  uploadedCount: (val: number, total: number) => void;
  miterType?: string;
};

export default function ImportExcel({
  open,
  handleClose,
  data,
  reRender,
  uploadedCount,
  miterType,
}: Props) {
  const dispatch = useAppDispatch();
  const [fileName, setFileName] = useState("No file chosen");
  const [loading, setLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const { excelLoading, downloadExcel, handleExcelLoading } =
    useExcelDownload("Bill-List");
  const [uploadLoading, setUploadLoading] = useState(false);
  const { postLoading, remainingDay } = useAppSelector(
    (state) => state.billGenerate
  );
  const { login } = useAppSelector((state) => state.login);

  const handleUploadFile = (file: any) => {
    if (
      file?.name?.endsWith(".xls") ||
      file?.name?.endsWith(".xlsx") ||
      file?.type === "application/vnd.ms-excel" ||
      file?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      const fileNameArr = stringToArray(file?.name, ".");
      setFileName(
        `${sliceString(file?.name, 30, "...")}.${
          fileNameArr?.[fileNameArr?.length - 1]
        }`
      );

      reader.onload = (e) => {
        const data = e?.target?.result;
        const workBook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          dateNF: "mm/dd/yyyy",
        });
        const sheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });
        // const filteredData = parsedData?.filter(
        //   (el: any) =>
        //     el?.["ID"] &&
        //     el?.["Tower Name"] &&
        //     (el?.["Per Minute Charge"] || el?.["Per Minute Charge"] === 0) &&
        //     el?.["Date"] &&
        //     el?.["Time"]
        // );

        setUploadedData(parsedData);
        setLoading(false);
      };
    } else {
      setLoading(false);
      toast("error", "Please upload only excel file");
    }
  };

  const handleDownloadExcel = async () => {
    handleExcelLoading(true);
    let excelData = [] as any[];

    const api =
      miterType === "pre" && remainingDay > 0
        ? BASE_API.getLastFiveDayRemainingData
        : BASE_API.getAllMiter;

    const params = miterType === "pre" && remainingDay > 0 ? {} : { miterType };

    await AXIOS.get(api, {
      params,
    }).then((res) => {
      const responseData = res?.data?.getData as any[];

      responseData?.forEach((el) => {
        let data = {} as any;
        data["Meter Name"] = capitalize(el?.miterName);
        data["Meter ID"] = el?._id;
        data["Company Name"] = el?.companyName?.toUpperCase();
        data["Company ID"] = el?.company;
        data["Tower ID"] = el?.tower?._id;
        data["Month"] = "";
        data["Bill Amount"] = "";
        data["Vat Amount"] = "";
        data["Meter Type"] =
          el?.miterType?.toLowerCase() === "pre"
            ? "Prepaid"
            : el?.miterType?.toLowerCase() === "post"
            ? "Postpaid"
            : el?.miterType;
        data["Status"] = "Unpaid";
        data["Region"] = login?.region;
        data["Year"] = new Date().getFullYear();
        if (miterType === "pre") {
          data["Next Payment"] =
            el?.miterType?.toLowerCase() === "pre" && el?.gapDay
              ? moment().add(el?.gapDay, "days").format("MM/DD/YYYY")
              : "";
        }
        // formatDate(new Date(), "MM/DD/YYYY")

        if (miterType === "post") {
          data["Bill Create Date"] = moment().format("MM/DD/YYYY");
          data["Meter Number"] = el?.miterNumber;
          data["Customer Number"] = "";
          data["Unit Consumption"] = "";
          data["Bill No"] = "";
          data["Meter Reading"] = "";
          data["Demand Charge"] = "";
          data["Office Code"] = "";
          data["Meter Rent"] = "";
          // data["Vat"] = "";
          // data["Total Bill"] = "";
          data["Last Date of Payment"] = "";
          data["Penalty Charge"] = "";
          data["Penalty Charge Date"] = "";
          data["From Date"] = "";
          data["To Date"] = "";
        }

        excelData.push(data);
      });
    });

    downloadExcel(excelData);
  };

  const handleUpload = async () => {
    setUploadLoading(true);
    try {
      const postData = (item: any) =>
        miterType === "post"
          ? {
              billCreateDate: moment(
                new Date(item?.["Bill Create Date"])
              ).format("YYYY-MM-DD"),
              miterNumber: item?.["Meter Number"],
              customerNumber: item?.["Customer Number"],
              unitConsumption: item?.["Unit Consumption"],
              billNo: item?.["Bill No"],
              miterReading: item?.["Meter Reading"],
              demandCharge: item?.["Demand Charge"],
              ofcCode: item?.["Office Code"],
              miterRent: item?.["Meter Rent"],
              totalBill: item?.["Vat Amount"] + item?.["Bill Amount"],
              lastDateOfPaymnet: moment(
                new Date(item?.["Last Date of Payment"])
              ).format("YYYY-MM-DD"),
              penaltyCharge: item?.["Penalty Charge"],
              penaltyChargeDate: moment(
                new Date(item?.["Penalty Charge Date"])
              ).format("YYYY-MM-DD"),
              fromDate: moment(new Date(item?.["From Date"])).format(
                "YYYY-MM-DD"
              ),
              toDate: moment(new Date(item?.["To Date"])).format("YYYY-MM-DD"),
            }
          : {};

      const uploadData = uploadedData?.map((el) => ({
        miterName: el?.["Meter Name"],
        miter: el?.["Meter ID"],
        companyName: el?.["Company Name"]?.toLowerCase(),
        company: el?.["Company ID"],
        month: el?.["Month"],
        billAmount: Number(el?.["Bill Amount"]) + Number(el?.["Vat Amount"]),
        vatAmount: el?.["Vat Amount"],
        miterType:
          el?.["Meter Type"]?.toLowerCase() === "prepaid"
            ? "pre"
            : el?.["Meter Type"]?.toLowerCase() === "postpaid"
            ? "post"
            : el?.["Meter Type"],
        status:
          el?.["Status"] === "Unpaid"
            ? 1
            : el?.["Status"] === "Processing"
            ? 2
            : el?.["Status"] === "Paid"
            ? 3
            : el?.["Status"] === "Recharge"
            ? 4
            : 1,
        tower: el?.["Tower ID"],
        region: el?.["Region"],
        year: el?.["Year"],
        nextPayment: moment(el?.["Next Payment"]).format("YYYY-MM-DD"),
        ...postData(el),
      }));

      if (uploadData.length > 0) {
        dispatch(
          uploadBillAsync({
            data: uploadData,
            others: {
              reRender,
              uploadedCount,
            },
          })
        );
      } else {
        toast("error", "Upload data not found");
      }
    } catch (error) {
      console.error("Error during the upload process", error);
      toast("error", "Error during the upload process");
    } finally {
      setUploadLoading(false);
    }
  };

  // function successDone(id: string) {
  //   setCountUpload((prev) => prev + 1);
  //   dispatch(
  //     towerTicketNotificationAsync({
  //       data: { towerTicketId: id },
  //       others: null,
  //     })
  //   );
  // }

  return (
    <CustomModal
      width={600}
      title={`${miterType === "pre" ? "Prepaid" : "Postpaid"} Excel Sheet`}
      open={open}
      handleClose={!uploadLoading && !postLoading ? handleClose : () => {}}
    >
      <div className="p-6">
        <label className="text-[#3d4a5c] text-sm font-medium leading-normal mb-1.5 inline-block">
          Import Excel File
        </label>
        <Dragger
          name="file"
          action=""
          accept=".xlsx, .xls"
          maxCount={1}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={(info) => handleUploadFile(info.file)}
          onDrop={(e) => handleUploadFile(e.dataTransfer.files[0])}
          // excelPostLoading ||
          disabled={loading || uploadLoading || postLoading}
          className="import_excel"
        >
          <Button
            className="flex justify-between items-center"
            size="large"
            disabled={loading || uploadLoading || postLoading}
            style={{ width: "100%" }}
          >
            <div className="flex items-center gap-2 text-sm font-normal font-roboto text-[#8797ae]">
              <span className="text-accent font-medium text-base leading-7 relative">
                Choose File
              </span>
              <span className="border-r border-solid border-l-0 border-y-0 border-others-border h-4" />
              {loading ? "Uploading..." : fileName}
            </div>
          </Button>
        </Dragger>

        <div className="px-4 py-[9px] bg-[#fffbe6] rounded-md border border-[#ffe58f] text-sm text-[#874d00] mt-4">
          <span className="font-medium">Warning! </span>
          <span>
            {/* For any blank column put N/A, otherwise data
            will be mismatch.  */}
            Upload only xlsx file. Your can see sample file,{" "}
          </span>
          <button
            className="text-[#1890ff] cursor-pointer text-sm font-medium bg-transparent border-none"
            onClick={handleDownloadExcel}
            disabled={excelLoading}
          >
            {excelLoading ? "downloading..." : "click here"}
          </button>
        </div>

        <div className="flex justify-center mt-7">
          <Button
            size="large"
            type="primary"
            className="w-72 !h-12"
            onClick={handleUpload}
            disabled={loading || uploadedData?.length === 0}
            loading={uploadLoading || postLoading}
          >
            Upload
          </Button>
        </div>
      </div>
    </CustomModal>
  );
}
