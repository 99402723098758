import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import TrashIcon from "../../../assets/icons/TrashIcon";
import ViewIcon from "../../../assets/icons/ViewIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllTerritoryAsync,
  getCountTerritoryAsync,
} from "../../../store/features/admin/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  CommonParams,
  GetTerritoryData,
  TableData,
} from "../../../types/redux";
import { countRow } from "../../../utils/number";

export default function Tower() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { territorys, loading, territoryCount } = useAppSelector(
    (state) => state.npGeo
  );

  const columns: ColumnsType<TableData<GetTerritoryData>> = [
    {
      title: "No.",
      align: "center",
      width: 60,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Site name & Code",
      dataIndex: "teritoryName",
      render: (val) => (
        <div>
          <span className="font-semibold">{val}</span>
          <br />
          <small className="text-xs font-normal leading-tight tracking-tight text-accent">
            Ref: e.coBD001846GP
          </small>
        </div>
      ),
    },
    {
      title: "Region",
      render: (val: GetTerritoryData) => val?.area?.areaName,
    },
    {
      title: "EPSC Type",
      render: (val: GetTerritoryData) => val?.teritoryName,
    },
    {
      title: "Meter Type",
      render: (_, __, idx) =>
        idx % 2 === 0 ? (
          <span className="status-info">Postpaid</span>
        ) : (
          <span className="status-waiting">Prepaid</span>
        ),
    },
    {
      title: "Postpaid Meters",
      render: (val: GetTerritoryData) => 10,
      width: 98,
    },

    {
      title: "Action",
      align: "center",
      render: (val: GetTerritoryData) => (
        <Space size="small">
          <Link to={`/admin/tower-info/view-supplier/${val?._id}`}>
            <Button icon={<ViewIcon />} />
          </Link>
          <Link to={`/admin/tower-info/update-supplier/${val?._id}`}>
            <Button icon={<EditIcon />} />
          </Link>
          <Button icon={<TrashIcon />} />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllTerritoryAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(getCountTerritoryAsync());
  }, [dispatch]);

  return (
    <AdminLayout title="Tower/Site List">
      <Subheader title="Tower/Site List">
        <Space size="middle">
          <Link to="/admin/tower-info/add-tower">
            <IconButton
              type="text"
              className="secondary-btn"
              icon={<AddIcon />}
            >
              Add New Tower
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<GetTerritoryData>>
        loading={loading}
        total={territoryCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(territorys)}
        bordered={false}
      />
    </AdminLayout>
  );
}
