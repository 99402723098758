import { Button, Upload, UploadFile, UploadProps } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { ReactNode, useState } from "react";
import FileIcon from "../../assets/icons/FileIcon";
import ImageCloseIcon from "../../assets/icons/ImageCloseIcon";
import AXIOS from "../../helpers/api";
import { BASE_API } from "../../helpers/apiUrl";
import { IMAGE_URL } from "../../helpers/config";
import { toast } from "../../helpers/toast";
import { stringToArray } from "../../utils/array";
import { sliceString } from "../../utils/string";

type Props = {
  errorMessage?: string;
  //   form: any;
  name: any;
  disabled?: boolean;
  fileType?: boolean;
  isPreview?: boolean;
  isGrid?: boolean;
  accept?: string;
  rootClassName?: string;
  imgClassName?: string;
  fileExtra?: ReactNode;
  fileNameSize?: number;
  showImageName?: boolean;
  onChangeImage?: (url: string | null) => void;
} & UploadProps;

export default function NormalFileUpload({
  errorMessage = "File upload fail",
  //   form,
  name,
  disabled,
  fileType,
  isPreview,
  isGrid,
  fileExtra,
  rootClassName = "",
  imgClassName = "",
  onChangeImage,
  accept = ".png,.jpeg,.svg",
  fileNameSize = 15,
  showImageName,
  ...rest
}: Props) {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("Browse image");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imgUrl, setImgUrl] = useState("");

  const handleUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("name", file.name);

    setLoading(true);

    try {
      const response = await AXIOS.post(`${BASE_API.fileUpload}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const url = response?.data?.photo;
      const fileNameArr = stringToArray(url, ".");

      if (response.status === 200) {
        setFileName(
          `${sliceString(url, fileNameSize, "...")}.${
            fileNameArr?.[fileNameArr?.length - 1]
          }`
        );

        onChangeImage && onChangeImage(url);
        setImgUrl(url);
        setLoading(false);
      }
    } catch (error) {
      toast("error", errorMessage);
      setLoading(false);
    }

    return false;
  };

  const handleChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList);

    if (info.file.status === "removed") {
      onChangeImage && onChangeImage(null);
      setImgUrl("");
      setFileName("");
    }
  };

  const handleRemovePreviewImage = () => {
    onChangeImage && onChangeImage(null);
    setImgUrl("");
    setFileName("");
  };

  //   useEffect(() => {
  //     if (fileType) {
  //       const file = form.getFieldValue([name]);
  //       const fileNameArr = stringToArray(file, ".");
  //       onChangeImage && onChangeImage(file);
  //       setImgUrl(file);

  //       setFileName(
  //         `${sliceString(file, 15, "...")}.${
  //           fileNameArr?.[fileNameArr?.length - 1]
  //         }`
  //       );
  //     }
  //   }, [form, name, fileType, onChangeImage]);

  return (
    <div className={isGrid ? "grid grid-cols-2 gap-4" : rootClassName}>
      <Upload
        name="photo"
        beforeUpload={handleUpload}
        maxCount={1}
        accept={accept}
        className="custom-upload"
        showUploadList={false}
        onChange={handleChange}
        fileList={fileList}
        disabled={disabled}
        style={{ width: "100%" }}
        {...rest}
      >
        <Button
          className="flex items-center"
          size="large"
          disabled={disabled}
          style={{ width: "100%" }}
        >
          <FileIcon />
          <div className="flex items-center pl-2 gap-2 text-sm font-medium leading-normal text-others-accent">
            <span className="border-r border-solid border-l-0 border-y-0 border-others-border h-4" />
            {loading
              ? "Uploading..."
              : showImageName
              ? fileName || "Browse image"
              : "Browse image"}
          </div>
        </Button>
        {fileExtra}
      </Upload>
      {isPreview ? (
        imgUrl ? (
          <div
            className={`w-[76px] h-[76px] bg-status-waiting-bg rounded-sm border border-others-secondary-border relative ${
              isGrid ? "-mt-4" : ""
            } ${imgClassName}`}
          >
            <img
              className="w-full h-full object-cover rounded-sm"
              src={IMAGE_URL + imgUrl}
              alt=""
            />
            <button
              className="p-0 m-0 border-transparent w-0 h-0 rounded-full absolute right-2 -top-3.5 bg-white cursor-pointer"
              type="button"
              onClick={handleRemovePreviewImage}
            >
              <ImageCloseIcon />
            </button>
          </div>
        ) : isGrid ? (
          <div />
        ) : null
      ) : null}
    </div>
  );
}
